import { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import { useAxiosWithLogOut } from '@hooks';
import { PaginatedResponse, Protection } from '@types';

/**
 * Fetches the full list of supported protections
 * */
export const useProtections = () => {
  const axiosWithInterceptors = useAxiosWithLogOut();

  const fetchPurposes = async () => {
    try {
      const response = await axiosWithInterceptors.get<PaginatedResponse<Protection>>(`${CONFIG.environmentConfig.apiBaseUrl}metadata/protections`);

      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  return useQuery<PaginatedResponse<Protection>, AxiosError>(['protections'], fetchPurposes);
};
