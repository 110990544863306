import React, { useState } from 'react';
import { generateUniqueId, getTranslatedValue } from '@didomi/helpers';
import { DidomiButton, DidomiSkeleton } from '@didomi/ui-atoms-react';
import { tx } from '@twind/core';
import { useField } from 'formik';
import { VendorStorageCard } from '@components';
import { useAllPurposes } from '@hooks';
import { AddVendorStorageModal } from '@modals';
import { VendorStorage } from '@types';

type VendorStoragesFieldProps = {
  name: string;
  isLoading?: boolean;
  className?: string;
};

const emptyVendorStorage = {
  deleted: false,
  type: 'cookie',
  identifier: '',
  domain: '',
  max_age: null,
  max_age_unit: 'months',
  purposes: [],
};

export const VendorStoragesField = ({ name, isLoading, className }: VendorStoragesFieldProps): JSX.Element => {
  const [, { value: storages, error, touched }, { setValue, setTouched }] = useField({ name });
  const [displayAddVendorStorageModal, setDisplayAddVendorStorageModal] = useState<boolean>(false);
  const [addVendorStorageInitialValues, setAddVendorStorageInitialValues] = useState<Partial<VendorStorage>>(emptyVendorStorage);
  const [editingVendorStorage, setEditingVendorStorage] = useState<string>(null);

  const { data: allPurposes, isLoading: isLoadingPurposesList } = useAllPurposes();

  const handleAddVendorStorage = (newVendorStorage: Partial<VendorStorage>) => {
    if (editingVendorStorage !== null) {
      const newStorages = storages?.map(v => {
        if (v.id === editingVendorStorage) {
          newVendorStorage['edited'] = true;
          return newVendorStorage;
        } else {
          return v;
        }
      });
      setValue(newStorages);
      setEditingVendorStorage(null);
    } else {
      newVendorStorage['created'] = true;
      newVendorStorage.id = 'temp-id-' + generateUniqueId();
      setValue(storages?.length ? storages.concat(newVendorStorage) : [newVendorStorage]);
    }
    setDisplayAddVendorStorageModal(false);
  };

  const handleRemoveVendorStorage = (vendorCookieId: string) => {
    const newStorages = storages?.map(v => {
      if (v.id === vendorCookieId) {
        return { ...v, deleted: true };
      } else {
        return v;
      }
    });
    setValue(newStorages);
  };

  const handleEditStorage = (vendorStorage: VendorStorage) => {
    setEditingVendorStorage(vendorStorage.id);
    setAddVendorStorageInitialValues(vendorStorage);
    setDisplayAddVendorStorageModal(true);
  };

  const showAddVendorStorageModal = () => {
    setEditingVendorStorage(null);
    setAddVendorStorageInitialValues(emptyVendorStorage);
    setDisplayAddVendorStorageModal(true);
  };

  const hideAddVendorStorageModal = () => {
    setTouched(true);
    setDisplayAddVendorStorageModal(false);
  };

  return (
    <div className={tx(className, 'flex flex-col justify-center items-center')}>
      <DidomiSkeleton variant="layout" className="w-full flex flex-col">
        {storages
          ?.filter(s => !s.deleted)
          .map((c, i) => (
            <VendorStorageCard
              className="w-full mb-s"
              key={'contact-' + i}
              data-testid={'storage-' + i}
              storageType={c.type}
              storageName={c.identifier}
              storageDomain={c.domain}
              cookieMaxDuration={c.max_age}
              cookieMaxDurationUnit={c.max_age_unit}
              purposes={allPurposes?.data?.filter(p => c.purposes_id?.includes(p.id))?.map(p => getTranslatedValue(p.description))}
              isLoading={isLoading || isLoadingPurposesList}
              onEdit={() => handleEditStorage(c)}
              onRemove={() => handleRemoveVendorStorage(c.id)}
            />
          ))}
      </DidomiSkeleton>

      <DidomiButton type="button" size="small" iconLeft="new-create" onClick={() => showAddVendorStorageModal()}>
        Add a new storage
      </DidomiButton>

      <AddVendorStorageModal
        isOpen={displayAddVendorStorageModal}
        initialValues={addVendorStorageInitialValues}
        isEditing={editingVendorStorage !== null}
        onConfirm={handleAddVendorStorage}
        onCancel={hideAddVendorStorageModal}
        onChange={
          /* istanbul ignore next - can't be tested with the mocks */ e => {
            if (!e.detail) hideAddVendorStorageModal();
          }
        }
      />
      {error && touched && <div className="text-secondary-red-4 font-sans text-[12px] mt-xxxs">{error}</div>}
    </div>
  );
};
