import React from 'react';
import { DidomiButtonGroup, DidomiIconButton } from '@didomi/ui-atoms-react';
import { tx } from '@twind/core';
import { VendorStorageType } from '@types';

type VendorStorageCardProps = {
  'data-testid'?: string;
  storageType: string;
  storageName: string;
  storageDomain: string;
  cookieMaxDuration: number;
  cookieMaxDurationUnit: string;
  purposes?: string[];
  isLoading?: boolean;
  className?: string;
  onEdit: () => void;
  onRemove: () => void;
};

export const VendorStorageCard = ({
  'data-testid': dataTestid,
  isLoading,
  className,
  storageType,
  storageName,
  storageDomain,
  cookieMaxDuration,
  cookieMaxDurationUnit,
  purposes,
  onEdit,
  onRemove,
}: VendorStorageCardProps): JSX.Element => (
  <div data-cy={'storage-card-' + VendorStorageType[storageType]} className={tx(className, 'h-full w-full flex py-s border-1 border-solid border-neutral-gray-5 rounded-lg p-s')}>
    <section className="flex-1 h-full flex flex-col text-primary-blue-6 gap-xs pr-s">
      <div>
        <div className="font-sans  text-[10px] leading-[13px] font-bold uppercase mb-xxxs">Type of storage</div>
        <div className="text-body-small" data-skeleton={isLoading}>
          {VendorStorageType[storageType]}
        </div>
      </div>
      <div>
        <div className="font-sans  text-[10px] leading-[13px] font-bold uppercase mb-xxxs">Name</div>
        <div className="text-body-small break-words" data-skeleton={isLoading}>
          {storageName}
        </div>
      </div>
      {storageType !== 'app_storage' && (
        <div>
          <div className="font-sans  text-[10px] leading-[13px] font-bold uppercase mb-xxxs">Domain</div>
          <div className="text-body-small break-words" data-skeleton={isLoading}>
            {storageDomain}
          </div>
        </div>
      )}
      {storageType === 'cookie' && (
        <div>
          <div className="font-sans  text-[10px] leading-[13px] font-bold uppercase mb-xxxs">Storage maximum duration</div>
          <div className="text-body-small break-words" data-skeleton={isLoading}>
            {cookieMaxDuration} {cookieMaxDurationUnit}
          </div>
        </div>
      )}
      <div>
        <div className="font-sans  text-[10px] leading-[13px] font-bold uppercase mb-xxxs">Used for the following purposes</div>
        <div className="text-body-small break-words" data-skeleton={isLoading}>
          {purposes?.join(', ') || '-'}
        </div>
      </div>
    </section>
    <section className="flex flex-col justify-center items-center border-l-1 border-solid border-neutral-gray-5 pl-s">
      <DidomiButtonGroup>
        <DidomiIconButton type="button" data-testid={dataTestid + '-edit'} title="Edit" variant="rounded" icon="edit" onClick={() => onEdit()} />
        <DidomiIconButton type="button" data-testid={dataTestid + '-delete'} title="Delete" variant="rounded" icon="delete" onClick={() => onRemove()} />
      </DidomiButtonGroup>
    </section>
  </div>
);
