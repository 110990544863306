import { useActiveOrganization } from '@didomi/utility-react';
import { AxiosError, AxiosResponse } from 'axios';
import { useMutation, UseMutationOptions } from 'react-query';
import { useAxiosWithLogOut } from '@hooks';
import { Vendor } from '@types';

/**
 * Forward the metadata-partners update/create signals to the CR API
 * to keep an up-to-date partners repository copy without stale data.
 * This is a solution to mitigate the 1~hour latency related to the Platform Stream sync.
 * */
export const useSyncCreatedVendorWithComplianceReport = (options?: UseMutationOptions<AxiosResponse<Partial<Vendor>>, AxiosError, Partial<Vendor>>) => {
  const axiosWithInterceptors = useAxiosWithLogOut();
  const { organizationId } = useActiveOrganization();

  const acceptedKeys = [
    'name',
    'tags',
    'links',
    'taxonomy_id',
    'contact',
    'country_id',
    'category_id',
    'protection_id',
    'address',
    'namespaces',
    'default_purposes_id',
    'legitimate_interest_purposes_id',
    'spi_purposes_id',
    'cookie_max_duration',
    'cookie_max_duration_unit',
    'uses_non_cookie_access',
    'deprecated',
    'notes',
    'hosts',
    'lang_urls',
    'id',
    'organization_id',
    'created_at',
    'updated_at',
    'sdk_id',
  ];

  return useMutation(
    async (vendor: Vendor) => {
      return axiosWithInterceptors.post(
        `${CONFIG.environmentConfig.apiBaseUrl}agnostik/metadata-partners?organization_id=${organizationId}`,
        // pick only the keys that the CR API accepts
        acceptedKeys.filter(key => vendor[key] !== undefined).reduce((acc, key) => ({ ...acc, [key]: vendor[key] }), {}),
      );
    },
    {
      ...options,
    },
  );
};
