import { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import { useAxiosWithLogOut } from '@hooks';
import { PaginatedResponse, VendorStorage } from '@types';

/**
 * Fetches the full list of vendor storages for a particular partner
 * */
export const useVendorStorages = (partnerId: string) => {
  const axiosWithInterceptors = useAxiosWithLogOut();

  const fetchVendorStorages = async () => {
    try {
      const response = await axiosWithInterceptors.get<PaginatedResponse<VendorStorage>>(
        `${CONFIG.environmentConfig.apiBaseUrl}metadata/partners-storages/?partner_id=${partnerId}`,
      );

      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  return useQuery<PaginatedResponse<VendorStorage>, AxiosError>(['vendors-storages', partnerId], fetchVendorStorages, {
    enabled: !!partnerId,
  });
};
