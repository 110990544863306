import React from 'react';
import { supportedLanguages } from '@didomi/helpers';
import { DidomiButton, DidomiSkeleton } from '@didomi/ui-atoms-react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { LanguagesField, SanitizedTextInputField } from '@components';
import { PurposeRequestBody } from '@types';

const PurposeSchema = Yup.object().shape({
  languages: Yup.array().required('You need to define at least one language').min(1, 'You need to define at least one language'),
  sdk_id: Yup.string().matches(/^([A-Za-z0-9-_]+)$/, 'You must provide a valid custom SDK ID'),
});

type PurposeFormProps = {
  initialValues: any;
  onSubmit?: (newPurpose: PurposeRequestBody) => void;
  onCancel?: () => void;
  isLoading?: boolean;
};

/**
 * Add/Edit Purpose Form
 */
export const PurposeForm = ({ initialValues, onCancel, onSubmit, isLoading = false }: PurposeFormProps): JSX.Element => {
  const handleSubmit = submitData => {
    const description = submitData?.languages?.reduce((result, value) => ({ ...result, [value.language.code]: value.description }), {});
    const details = submitData?.languages?.reduce((result, value) => ({ ...result, [value.language.code]: value.details }), {});
    const purposeDetails = submitData?.sdk_id
      ? {
          description,
          details,
          namespaces: {
            custom: submitData?.sdk_id,
          },
        }
      : { description, details };

    onSubmit(purposeDetails);
  };

  let languages = [];

  if (initialValues?.description) {
    languages = Object.keys(initialValues.description).map(lang => ({
      language: supportedLanguages.find(sL => sL.code === lang),
      description: initialValues.description[lang],
      details: initialValues.details?.[lang],
    }));
  }

  const mappedInitialValues = {
    sdk_id: initialValues?.namespaces?.custom,
    languages,
  };

  return (
    <Formik className="w-full" initialValues={mappedInitialValues} validationSchema={PurposeSchema} onSubmit={handleSubmit} enableReinitialize validateOnMount={true}>
      {({ isValid }) => (
        <DidomiSkeleton className="w-full" isLoading={isLoading} variant="layout" data-testid={`preference-form-skeleton-loading-${isLoading}`}>
          <Form className="w-full mt-s flex flex-col mb-s">
            <LanguagesField name="languages" className="w-full mb-s" isLoading={isLoading} />
            <hr />

            <section className="text-body-small text-primary-blue-6 w-full flex flex-col mt-s mb-m max-w-[520px]">
              <header className=" text-body-normal font-semibold mb-xs">Advanced settings</header>
              You can specify an SDK ID that maps to this purpose. Only do it if you are importing existing consent notices from JSON configurations and need to force set the SDK
              ID of this purpose to a custom purpose ID used in your JSON configuration.
              <SanitizedTextInputField name="sdk_id" className="mt-s" label="Custom SDK ID" placeholder="Enter a custom SDK ID…" data-skeleton={isLoading} />
            </section>

            <div className="w-full flex justify-start">
              <DidomiButton type="button" variant="secondary" className="mr-xs" disabled={isLoading} onClick={onCancel} data-skeleton>
                Cancel
              </DidomiButton>
              <DidomiButton data-tracking="purpose-save-button" type="submit" data-skeleton disabled={!isValid || isLoading}>
                Save
              </DidomiButton>
            </div>
          </Form>
        </DidomiSkeleton>
      )}
    </Formik>
  );
};
