import React, { ReactChild } from 'react';
import { useSearchParams } from 'react-router-dom';
import { DidomiSecondaryHeader, DidomiBackButtonLink, DidomiIllustration } from '@didomi/ui-atoms-react';
import { Illustrations } from '@didomi/ui-foundation';
import { tx } from '@twind/core';
import { useBackNavigation } from '@hooks';

interface IMainLayoutProps {
  titleText?: string;
  backPath?: string;
  backText?: string;
  description?: string | React.ReactChild;
  illustration?: Illustrations;
  onGoBack?: () => void;
  fullWidth?: boolean;
  headerChildren?: ReactChild;
  actionChildren?: ReactChild;
  children: React.ReactChild;
}

/**
 * Secondary Layout
 * Default layout with header for secondary pages
 */
const SecondaryLayout = ({ titleText, description, backPath, backText, children, illustration, headerChildren, actionChildren, onGoBack }: IMainLayoutProps): JSX.Element => {
  const [searchParams] = useSearchParams();
  const navigateBack = useBackNavigation();
  const matchingVendorHost = searchParams.get('vendorKey');
  return (
    <section className="p-12 pb-6 h-full box-border !flex flex-col">
      <DidomiSecondaryHeader titleText={matchingVendorHost ? `Matching: ${matchingVendorHost}` : titleText}>
        <div slot="back-button">
          <DidomiBackButtonLink
            text={backText}
            class="cursor-pointer"
            onClick={() => {
              if (onGoBack) {
                onGoBack();
              }
              if (backPath) {
                navigateBack(backPath);
              }
            }}
          />
        </div>
        {!titleText && !matchingVendorHost && <div slot="header-title">{headerChildren}</div>}
        <div slot="actions">{actionChildren}</div>
        {description && !matchingVendorHost && <div slot="description">{description}</div>}
      </DidomiSecondaryHeader>
      <div className="w-full mt-m h-full flex">
        <div className={tx('h-full', { 'w-full': !illustration })}>{children}</div>
        {illustration && (
          <div className="flex-1 flex justify-center ml-xl">
            <DidomiIllustration name={illustration} className="min-w-[292px] hidden lg:block" />
          </div>
        )}
      </div>
    </section>
  );
};

export { SecondaryLayout };
