/**
 * This function extracts two ids from a URL search parameter.
 * Its used to get reportId and propertyId used in the ACM app
 *
 * The function takes a string as input, which should be in the format "id1[id2]".
 * It then uses regular expressions to separate id1 and id2.
 *
 */
export const extractIdsFromParams = str => {
  if (typeof str !== 'string') {
    return ['', ''];
  }

  const id1Match = str.match(/(.*?)(?=\[)/g);
  const id2Match = str.match(/(?<=\[)(.*?)(?=\])/g);

  const id1 = id1Match ? id1Match[0] : '';
  const id2 = id2Match ? id2Match[0] : '';

  return [id1, id2];
};
