// Handle the appropriate redirection after creating a vendor
// depending from which flow (CMP Vendors sync or ACM or Data manager) the vendor was created
export const handleRedirectionAfterMatching = ({ vendorKey, reportDomainId, domainId, reportId, templateId, navigateTo, navigateBack }) => {
  if (vendorKey && reportDomainId) {
    navigateTo(`agnostik-compliance-report/domain/${domainId}/match-unknown-domains/${reportId}`);
  } else if (vendorKey && templateId) {
    navigateTo(`smart-vendor-list/${templateId}?activeTab=vendor&refresh=true`);
  } else {
    navigateBack('/vendors');
  }
};
