import React, { useMemo, useState } from 'react';
import { DidomiButton } from '@didomi/ui-atoms-react';
import { tx } from '@twind/core';
import { SelectSpiModal } from '@modals';
import { Purpose } from '@types';
import { SpiSelectionList } from '../SpiSelectionList/SpiSelectionList';

interface SpiSelectionWidgetProps {
  className?: string;
  selectedSpiIds: string[];
  availableSpiPurposes: Purpose[];
  onSelectionChange: (selectedSpiIds: string[]) => void;
}

export const SpiSelectionWidget = ({ className, selectedSpiIds = [], availableSpiPurposes = [], onSelectionChange }: SpiSelectionWidgetProps) => {
  const [isSpiModalVisible, setIsSpiModalVisible] = useState(false);

  const selectedSpiPurposes = useMemo(() => availableSpiPurposes.filter(p => selectedSpiIds.includes(p.id)), [availableSpiPurposes, selectedSpiIds]);
  const hasSelectedIds = selectedSpiIds.length !== 0;
  const ctaAdditionalProps = hasSelectedIds ? { children: 'Manage SPI' } : { iconLeft: 'new-create', children: 'Add SPI' };

  return (
    <section className={tx(className, 'flex flex-col items-center')}>
      {/* List of selected SPI Purposes */}
      {hasSelectedIds && <SpiSelectionList className="mb-xs w-full" disabled={true} selectedIds={selectedSpiIds} spiPurposes={selectedSpiPurposes} />}

      {/* Use key in order to update button when selection state changes - otherwise button is rendered incorrectly(reason unknown) */}
      <DidomiButton key={String(hasSelectedIds)} size="small" type="button" {...ctaAdditionalProps} onClick={() => setIsSpiModalVisible(true)} />

      {/* Modals */}
      <SelectSpiModal
        isOpen={isSpiModalVisible}
        initialSpiSelection={selectedSpiIds}
        spiPurposes={availableSpiPurposes}
        onSave={spiIds => {
          onSelectionChange(spiIds);
          setIsSpiModalVisible(false);
        }}
        onCancel={() => setIsSpiModalVisible(false)}
      />
    </section>
  );
};
