import { useActiveOrganization } from '@didomi/utility-react';
import { AxiosError, AxiosResponse } from 'axios';
import { useMutation, UseMutationOptions } from 'react-query';
import { useAxiosWithLogOut } from '@hooks';
import { Vendor } from '@types';

/**
 * Forward the metadata-partners update/create signals to the CR API
 * to keep an up-to-date partners repository copy without stale data.
 * This is a solution to mitigate the 1~hour latency related to the Platform Stream sync.
 * */
export const useSyncPatchVendorWithComplianceReport = (options?: UseMutationOptions<AxiosResponse<Partial<Vendor>>, AxiosError, Partial<Vendor>>) => {
  const axiosWithInterceptors = useAxiosWithLogOut();
  const { organizationId } = useActiveOrganization();

  return useMutation(
    async (vendor: Vendor) => {
      return axiosWithInterceptors.patch(`${CONFIG.environmentConfig.apiBaseUrl}agnostik/metadata-partners/${vendor.id}?organization_id=${organizationId}`, {
        hosts: vendor.hosts,
      });
    },
    {
      ...options,
    },
  );
};
