import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getTranslatedValue } from '@didomi/helpers';
import { DidomiErrorState } from '@didomi/ui-atoms-react';
import { useSnackbar } from '@didomi/utility-react';
import { PurposeForm } from '@components';
import { useEditPurpose, usePurpose } from '@hooks';
import { LoadingModal } from '@modals';

/**
 * Edit Purpose Page
 */
export const EditPurpose = (): JSX.Element => {
  const { displaySnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { purposeId } = useParams();

  const { data: purpose, isLoading: loadingPurpose, error: errorLoadingPurpose } = usePurpose(purposeId);

  const { mutate: editPurpose, isLoading: isCreatingPurpose } = useEditPurpose(purposeId, {
    onSuccess: ({ data: editedPurpose }) => {
      displaySnackbar(`Purpose "${getTranslatedValue(editedPurpose.description)}" has been edited successfully!`, { icon: 'check' });
      navigate('/purposes');
    },
    onError: () => {
      displaySnackbar('There was an error editing the purpose', { icon: 'danger-light', variant: 'error' });
    },
  });

  if (!loadingPurpose && !!errorLoadingPurpose) {
    if (errorLoadingPurpose.response.statusText === 'Not Found') {
      return (
        <DidomiErrorState illustrationName="no-matches-found" className="h-full">
          <div slot="title">The purpose does not exist in our records</div>
        </DidomiErrorState>
      );
    }
  }

  return (
    <main className="w-full pb-10">
      <PurposeForm initialValues={purpose} onSubmit={editPurpose} onCancel={() => navigate('/purposes')} isLoading={loadingPurpose} />
      <LoadingModal data-cy="edit-purpose-saving" isOpen={isCreatingPurpose} title="We are updating your purpose..." />
    </main>
  );
};
