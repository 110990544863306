import React from 'react';
import { getTranslatedValue } from '@didomi/helpers';
import { DidomiCheckbox } from '@didomi/ui-atoms-react';
import { tx } from '@twind/core';
import { Purpose } from '@types';

interface SpiSelectionListProps {
  className?: string;
  spiPurposes: Purpose[];
  selectedIds: string[];
  disabled?: boolean;
  onSelectionChange?: (selectedIds: string[]) => void;
}

export const SpiSelectionList = ({ className, selectedIds, disabled, spiPurposes, onSelectionChange }: SpiSelectionListProps) => {
  const handleSpiPurposeChange = (id: string) => {
    const updatedSelectedSpiIds = selectedIds.includes(id) ? selectedIds.filter(s => s !== id) : [...selectedIds, id];
    onSelectionChange(updatedSelectedSpiIds);
  };

  return (
    <ul data-testid="spi-selection-list" className={tx(className, 'flex flex-col gap-xxs text-left relative overflow-y-auto max-h-[300px] lg:max-h-[400px] 2xl:max-h-[500px]')}>
      {spiPurposes?.map(spiPurpose => (
        <li key={spiPurpose.id} data-testid="spi-selection-list-item" className={tx('bg-neutral-gray-1 rounded p-xs')}>
          <DidomiCheckbox
            checkboxId={spiPurpose.id}
            name={spiPurpose.id}
            className={tx('mb-xxxs')}
            label={getTranslatedValue(spiPurpose.description)}
            checked={selectedIds.includes(spiPurpose.id)}
            disabled={disabled}
            hideErrorMessage
            onValueChange={() => handleSpiPurposeChange(spiPurpose.id)}
          />

          {/* List of regulations assigned to the SPI Purpose */}
          <ul className={tx('flex flex-wrap gap-xxs font-semibold text-body-extra-small text-secondary-cobalt-blue-3 ml-[30px]')}>
            {spiPurpose.regulations_id.map(r => (
              <li key={r}>{r.toUpperCase()}</li>
            ))}
          </ul>
        </li>
      ))}
    </ul>
  );
};
