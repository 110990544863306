import { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import { useAxiosWithLogOut } from '@hooks';
import { Vendor } from '@types';

/**
 * Fetches a particular vendor
 * */
export const useVendor = (vendorId: string) => {
  const axiosWithInterceptors = useAxiosWithLogOut();

  const fetchPurpose = async () => {
    const response = await axiosWithInterceptors.get<Vendor>(`${CONFIG.environmentConfig.apiBaseUrl}metadata/partners/${vendorId}?$translations=true`);

    return response.data;
  };

  return useQuery<Vendor, AxiosError>(['vendor', vendorId], fetchPurpose, {
    enabled: !!vendorId,
  });
};
