import React, { useMemo, useState } from 'react';
import { supportedLanguages } from '@didomi/helpers';
import { DidomiButton } from '@didomi/ui-atoms-react';
import { tx } from '@twind/core';
import { useField } from 'formik';
import { LanguageCard } from '@components';
import { AddPurposeLanguageModal } from '@modals';
import { LanguageForm } from '@types';

type LanguagesFieldProps = {
  name: string;
  isLoading?: boolean;
  className?: string;
};

const emptyAddPurposeValues = {
  language: '',
  description: '',
  details: '',
};

export const LanguagesField = ({ name, isLoading, className }: LanguagesFieldProps): JSX.Element => {
  const [, { value, error, touched }, { setValue, setError, setTouched }] = useField({ name });
  const [displayAddLanguageModal, setDisplayAddLanguageModal] = useState(false);
  const [addPurposeLanguageInitialValues, setAddPurposeLanguageInitialValues] = useState(emptyAddPurposeValues);
  const [editingLanguage, setEditingLanguage] = useState<string>(null);

  const noLanguageYet = !value?.length;

  const showAddLanguageModal = () => {
    setAddPurposeLanguageInitialValues(emptyAddPurposeValues);
    setDisplayAddLanguageModal(true);
    setTouched(true);
  };

  const hideAddLanguageModal = () => {
    setEditingLanguage(null);
    setDisplayAddLanguageModal(false);
  };

  const handleAddLanguage = (newLanguage: LanguageForm) => {
    if (editingLanguage) {
      setValue(
        value?.map(v => {
          if (v.language.code === editingLanguage) {
            return newLanguage;
          } else {
            return v;
          }
        }),
      );
    } else {
      setValue(value?.length ? value.concat(newLanguage) : [newLanguage]);
    }
    setDisplayAddLanguageModal(false);
  };

  const handleRemoveLanguage = (languageCode: string) => {
    const newValue = value?.filter(v => v?.language?.code !== languageCode);
    setValue(newValue);
    if (!newValue?.length) {
      setError('You need to define at least one language');
    }
  };

  const handleEditLanguage = (languageCode: string) => {
    setEditingLanguage(languageCode);
    const languageValue = value?.find(v => v?.language?.code === languageCode);
    setAddPurposeLanguageInitialValues({
      ...languageValue,
      language: languageValue?.language.code,
    });
    setDisplayAddLanguageModal(true);
  };

  const sortedFlags = useMemo(
    () =>
      value?.length ? value.sort((a, b) => supportedLanguages.findIndex(l => l.code === a.language.code) - supportedLanguages.findIndex(l => l.code === b.language.code)) : [],
    [value],
  );

  return (
    <div className={className}>
      {noLanguageYet && (
        <div
          data-skeleton={isLoading}
          className={tx('h-[180px] max-w-[512px] w-full flex flex-col justify-center items-center border-1 border-solid border-neutral-gray-5 bg-neutral-gray-1 rounded-lg')}
        >
          <span className="text-primary-blue-6 text-body-small mb-s">Create a purpose and define its language</span>
          <DidomiButton type="button" variant="secondary" size="small" iconRight="new-create" disabled={isLoading} onClick={showAddLanguageModal}>
            Create a new purpose
          </DidomiButton>
        </div>
      )}
      {!noLanguageYet && (
        <div className="w-full flex flex-col">
          <div className="w-full flex flex-wrap gap-xs">
            {sortedFlags?.map(values => (
              <LanguageCard
                data-testid={'language-card-' + values.language?.code}
                key={'card-' + values.language?.code}
                isLoading={isLoading}
                {...values}
                onEdit={handleEditLanguage}
                onRemove={handleRemoveLanguage}
              />
            ))}
          </div>
          <DidomiButton type="button" className="mt-s" variant="secondary" size="small" iconRight="new-create" onClick={showAddLanguageModal}>
            Create a new purpose
          </DidomiButton>
        </div>
      )}
      <AddPurposeLanguageModal
        isOpen={displayAddLanguageModal}
        initialValues={addPurposeLanguageInitialValues}
        languageList={supportedLanguages}
        isEditing={!!editingLanguage}
        disabledLanguages={value?.map(v => v?.language?.code)}
        onConfirm={handleAddLanguage}
        onCancel={hideAddLanguageModal}
        onChange={
          /* istanbul ignore next - can't be tested with the mocks */ e => {
            if (!e.detail) hideAddLanguageModal();
          }
        }
      />
      {error && touched && <div className="text-secondary-red-4 font-sans text-[12px] mt-xxxs">{error}</div>}
    </div>
  );
};
