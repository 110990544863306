import React from 'react';
import { DidomiButton, DidomiModal, DidomiModalActions, DidomiModalHeader } from '@didomi/ui-atoms-react';
import { tx } from '@twind/core';

type LoadingModalProps = {
  title?: string;
  isOpen: boolean;
  handleClick?: Function;
};

export const SelectUnmatchedVendorDomainsGuard = ({ isOpen, title, handleClick }: LoadingModalProps) => (
  <DidomiModal isOpen={isOpen} permanent={true}>
    <DidomiModalHeader modalSubTitle={title} variant="warning" />
    <p className={tx('text-primary-blue-6, mt-xs')} style={{ fontSize: '14px' }}>
      Please select at least one of your domains from the list before <br />
      continuing.
    </p>
    <DidomiModalActions>
      <DidomiButton onClick={() => handleClick()}>Ok</DidomiButton>
    </DidomiModalActions>
  </DidomiModal>
);
