import React, { useEffect, useMemo, useState } from 'react';
import { DidomiButton, DidomiModal, DidomiModalActions, DidomiModalContent } from '@didomi/ui-atoms-react';
import { tx } from '@twind/core';
import { SpiSelectionFilters, SpiSelectionList } from '@components';
import { Purpose } from '@types';

type SelectSpiModalProps = {
  isOpen: boolean;
  onCancel: () => void;
  spiPurposes: Purpose[];
  initialSpiSelection: string[];
  onSave: (selectedSpiIds: string[]) => void;
};

export const SelectSpiModal = ({ isOpen, spiPurposes, initialSpiSelection, onCancel, onSave }: SelectSpiModalProps) => {
  const [selectedRegulationsFilter, setSelectedRegulationFilter] = useState<string[]>([]);
  const [selectedSpiIds, setSelectedSpiIds] = useState<string[]>([]);

  // Extract unique regulations from the SPI purposes
  const regulations = useMemo(() => {
    const allRegulations = spiPurposes.reduce((acc, p) => [...acc, ...p.regulations_id], [] as string[]);
    return Array.from(new Set(allRegulations));
  }, [spiPurposes]);

  const filteredSpiPurposes = useMemo(() => {
    if (selectedRegulationsFilter.length === 0) return spiPurposes;
    return spiPurposes.filter(p => p.regulations_id.some(r => selectedRegulationsFilter.includes(r)));
  }, [spiPurposes, selectedRegulationsFilter]);

  useEffect(() => {
    // reset selection only when modal is closed
    if (!isOpen) {
      setSelectedSpiIds(initialSpiSelection);
    }
  }, [initialSpiSelection, isOpen]);

  // Show filters only if there are more than one regulation
  const areFiltersVisible = regulations.length > 1;

  /* istanbul ignore next */
  const handleModalChange = (e: CustomEvent<boolean>) => {
    if (isOpen && !e.detail) {
      onCancel();
    }
  };

  return (
    <DidomiModal isOpen={isOpen} closable="true" onOpenChange={handleModalChange}>
      <div className={tx('text-body-extra-small text-primary-blue-6')}>Sensitive Personal Information (SPI)</div>
      <div className={tx('text-h3 text-primary-blue-6 font-bold font-serif mb-s')}>Select the SPI you want to cover</div>

      <DidomiModalContent>
        {areFiltersVisible && (
          <SpiSelectionFilters allRegulations={regulations} selectedRegulations={selectedRegulationsFilter} onRegulationSelectionChange={setSelectedRegulationFilter} />
        )}
        <SpiSelectionList spiPurposes={filteredSpiPurposes} selectedIds={selectedSpiIds} onSelectionChange={ids => setSelectedSpiIds(ids)} />
      </DidomiModalContent>

      <DidomiModalActions>
        <DidomiButton type="button" variant="secondary" onClick={onCancel}>
          Cancel
        </DidomiButton>
        <DidomiButton data-testid="save-spi" type="submit" onClick={() => onSave(selectedSpiIds)}>
          Save
        </DidomiButton>
      </DidomiModalActions>
    </DidomiModal>
  );
};
