import { AxiosError, AxiosResponse } from 'axios';
import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import { useAxiosWithLogOut } from '@hooks';
import { PaginatedResponse, VendorStorage } from '@types';

/**
 * Deletes a vendor storage, makes sure to update the list of vendor storages for the vendor
 * */
export const useDeleteVendorStorage = (options?: UseMutationOptions<AxiosResponse<VendorStorage>, AxiosError, string>) => {
  const axiosWithInterceptors = useAxiosWithLogOut();
  const queryClient = useQueryClient();

  return useMutation(
    async (partnerId: string) => {
      return axiosWithInterceptors.delete(`${CONFIG.environmentConfig.apiBaseUrl}metadata/partners-storages/${partnerId}`);
    },
    {
      ...options,
      onSuccess: (deletedVendorStorage: AxiosResponse<VendorStorage>, variables, context) => {
        const vendorsStoragesGet: PaginatedResponse<VendorStorage> = queryClient.getQueryData(['vendors-storages', deletedVendorStorage?.data.partner_id]);
        /* istanbul ignore if */
        if (vendorsStoragesGet) {
          queryClient.setQueryData(['vendors-storages', deletedVendorStorage?.data.partner_id], {
            ...vendorsStoragesGet,
            data: vendorsStoragesGet.data.filter(p => p.id !== deletedVendorStorage.data.id),
            total: vendorsStoragesGet.total - 1,
          });
        }

        if (options?.onSuccess) {
          options.onSuccess(deletedVendorStorage, variables, context);
        }
      },
    },
  );
};
