import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { DidomiLoader } from '@didomi/ui-atoms-react';
import { useHasAccessPolicies } from '@didomi/utility-react';
import { ACCESS_POLICIES_CONFIG } from '@access';
import { useBaseName } from '@hooks';
import { MainLayout, SecondaryLayout, MatchingVendorLayout } from '@layouts';
import { Purposes, Vendors, AddPurpose, AddVendor, EditPurpose, EditVendor, MatchVendorDomains } from '@pages';

const App = () => {
  const basename = useBaseName();
  const { hasAccess: canEdit, isLoading: isAccessLoading } = useHasAccessPolicies(ACCESS_POLICIES_CONFIG.EDITOR);

  if (isAccessLoading) {
    return (
      <div data-testid="loading-app" className="w-full h-full flex justify-center items-center">
        <DidomiLoader />
      </div>
    );
  }

  return (
    <BrowserRouter basename={`${basename}/data-manager`}>
      <Routes>
        <Route
          path="/purposes"
          element={
            <MainLayout>
              <Purposes />
            </MainLayout>
          }
        ></Route>
        <Route
          path="/vendors"
          element={
            <MainLayout>
              <Vendors />
            </MainLayout>
          }
        ></Route>
        {canEdit && (
          <Route
            path="/add-purpose"
            element={
              <SecondaryLayout
                titleText="Add a new purpose"
                description="A purpose must describe why you are collecting personal data from your users and how you process it."
                backText="Back to purposes list"
                backPath="/purposes"
              >
                <AddPurpose />
              </SecondaryLayout>
            }
          ></Route>
        )}
        {canEdit && (
          <Route path="/add-vendor">
            <Route
              index
              element={
                <SecondaryLayout
                  titleText="Add a new vendor"
                  description="A vendor is a third party that you are sharing data with (recipient) or collecting data from (external source)"
                  backText="Back to vendor list"
                  backPath="/vendors"
                  illustration="add-vendor"
                >
                  <AddVendor />
                </SecondaryLayout>
              }
            ></Route>
            <Route
              path="/add-vendor/associate-domains"
              element={
                <MatchingVendorLayout titleText="Suggested domain matches for" backText="Back creation page">
                  <MatchVendorDomains />
                </MatchingVendorLayout>
              }
            ></Route>
          </Route>
        )}
        {canEdit && (
          <Route
            path="/purpose/:purposeId"
            element={
              <SecondaryLayout
                titleText="Edit a purpose"
                description="A purpose must describe why you are collecting personal data from your users and how you process it."
                backText="Back to purposes list"
                backPath="/purposes"
              >
                <EditPurpose />
              </SecondaryLayout>
            }
          ></Route>
        )}
        {canEdit && (
          <Route path="/vendor/:vendorId">
            <Route
              index
              element={
                <SecondaryLayout
                  titleText="Edit a vendor"
                  description="A vendor is a third party that you are sharing data with (recipient) or collecting data from (external source)"
                  backText="Back to vendors list"
                  backPath="/vendors"
                  illustration="add-vendor"
                >
                  <EditVendor />
                </SecondaryLayout>
              }
            ></Route>
            <Route
              path="/vendor/:vendorId/associate-domains"
              element={
                <MatchingVendorLayout titleText="Match domains with" backText="Back creation page">
                  <MatchVendorDomains />
                </MatchingVendorLayout>
              }
            ></Route>
          </Route>
        )}
        <Route path="*" element={<Navigate to="/purposes" />} />
      </Routes>
    </BrowserRouter>
  );
};

export { App };
