import { AxiosError, AxiosResponse } from 'axios';
import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import { useAxiosWithLogOut } from '@hooks';
import { PaginatedResponse, VendorStorage, VendorStorageRequestBody } from '@types';

/**
 * Creates a vendor storage
 * */
export const useCreateVendorStorage = (options?: UseMutationOptions<AxiosResponse<VendorStorage>, AxiosError, VendorStorageRequestBody>) => {
  const axiosWithInterceptors = useAxiosWithLogOut();
  const queryClient = useQueryClient();

  return useMutation(
    async (vendorStorage: VendorStorageRequestBody) => {
      if (vendorStorage.type !== 'cookie') {
        delete vendorStorage.max_age;
        delete vendorStorage.max_age_unit;
      }
      if (vendorStorage.type === 'app_storage') {
        delete vendorStorage.domain;
      }

      return axiosWithInterceptors.post(`${CONFIG.environmentConfig.apiBaseUrl}metadata/partners-storages`, vendorStorage);
    },
    {
      ...options,
      onSuccess: (newVendorStorage: AxiosResponse<VendorStorage>, variables, context) => {
        const vendorsStoragesGet: PaginatedResponse<VendorStorage> = queryClient.getQueryData(['vendors-storages', newVendorStorage?.data?.partner_id]);

        /* istanbul ignore if */
        if (vendorsStoragesGet) {
          queryClient.setQueryData(['vendors-storages', newVendorStorage?.data?.partner_id], {
            data: vendorsStoragesGet?.data?.concat(newVendorStorage?.data),
            total: vendorsStoragesGet?.total + 1,
          });
        } else {
          queryClient.setQueryData(['vendors-storages', newVendorStorage?.data?.partner_id], {
            data: [newVendorStorage?.data],
            total: 1,
          });
        }

        if (options?.onSuccess) {
          options.onSuccess(newVendorStorage, variables, context);
        }
      },
    },
  );
};
