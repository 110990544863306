import { useActiveOrganization } from '@didomi/utility-react';
import { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import { useAxiosWithLogOut } from '@hooks';
import { PaginatedResponse, Purpose } from '@types';

/**
 * Fetches the full (max 5000) list of purposes corresponding to the loaded organization
 * */
export const usePurposes = () => {
  const { organizationId } = useActiveOrganization();
  const axiosWithInterceptors = useAxiosWithLogOut();

  const fetchPurposes = async () => {
    try {
      const response = await axiosWithInterceptors.get<PaginatedResponse<Purpose>>(
        `${CONFIG.environmentConfig.apiBaseUrl}metadata/purposes?$translations=true&$limit=5000&organization_id=${organizationId}`,
      );

      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  return useQuery<PaginatedResponse<Purpose>, AxiosError>(['purposes', organizationId], fetchPurposes);
};
