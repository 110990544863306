import { useCustomFeature } from '@didomi/utility-react';

// Get the release status config for regulations depending on the environment and custom flags(`enable-release-candidate-regulations`)
export const useRegulationReleaseStatusConfig = () => {
  const [hasEnabledReleaseCandidate] = useCustomFeature('enable-release-candidate-regulations');

  const releaseStatusConfigPerEnv = {
    staging: {
      alpha: 'coming-soon',
      beta: 'visible',
      'release-candidate': 'visible',
      stable: 'visible',
    },
    production: {
      alpha: 'hidden',
      beta: 'coming-soon',
      'release-candidate': hasEnabledReleaseCandidate ? 'visible' : 'coming-soon',
      stable: 'visible',
    },
  } as const;

  return {
    releaseStatusConfig: CONFIG.production ? releaseStatusConfigPerEnv.production : releaseStatusConfigPerEnv.staging,
  };
};
