import { AxiosError, AxiosResponse } from 'axios';
import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import { useAxiosWithLogOut } from '@hooks';
import { PaginatedResponse, VendorStorage, VendorStorageRequestBody } from '@types';

/**
 * Edits a vendor storage
 * */
export const useEditVendorStorage = (
  options?: UseMutationOptions<AxiosResponse<VendorStorage>, AxiosError, { vendorStorageId: string; vendorStorage: VendorStorageRequestBody }>,
) => {
  const axiosWithInterceptors = useAxiosWithLogOut();
  const queryClient = useQueryClient();

  return useMutation(
    async ({ vendorStorageId, vendorStorage }) => {
      if (vendorStorage.type !== 'cookie') {
        delete vendorStorage.max_age;
        delete vendorStorage.max_age_unit;
      }
      if (vendorStorage.type === 'app_storage') {
        delete vendorStorage.domain;
      }

      return axiosWithInterceptors.patch(`${CONFIG.environmentConfig.apiBaseUrl}metadata/partners-storages/${vendorStorageId}`, vendorStorage);
    },
    {
      ...options,
      onSuccess: (editedVendorStorage: AxiosResponse<VendorStorage>, variables, context) => {
        const vendorsStoragesGet: PaginatedResponse<VendorStorage> = queryClient.getQueryData(['vendors-storages', editedVendorStorage?.data?.partner_id]);

        /* istanbul ignore if */
        if (vendorsStoragesGet) {
          const newData = vendorsStoragesGet?.data?.map(vS => {
            if (vS.id === editedVendorStorage.data.id) {
              return editedVendorStorage.data;
            }
            return vS;
          });
          queryClient.setQueryData(['vendors-storages', editedVendorStorage?.data?.partner_id], {
            ...vendorsStoragesGet,
            data: newData,
          });
        }

        if (options?.onSuccess) {
          options.onSuccess(editedVendorStorage, variables, context);
        }
      },
    },
  );
};
