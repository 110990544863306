import React from 'react';
import { DidomiButton, DidomiIllustration, DidomiModal, DidomiModalActions, DidomiModalHeader } from '@didomi/ui-atoms-react';
import { tx } from '@twind/core';

type InvalidLiPurposesModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

export const InvalidLiPurposesModal = ({ isOpen, onClose }: InvalidLiPurposesModalProps) => {
  /* istanbul ignore next */
  const handleModalChange = (e: CustomEvent<boolean>) => {
    if (isOpen && !e.detail) {
      onClose();
    }
  };

  return (
    <DidomiModal data-testid="invalid-li-purposes-modal" isOpen={isOpen} closable="true" onOpenChange={handleModalChange}>
      <DidomiIllustration className={tx('mb-xs')} name="warning-modal" />
      <DidomiModalHeader
        variant="warning"
        modalSubTitle='Under TCF v2.2, purposes 1, 3, 4, 5 and 6 cannot use "legitimate interest" as an acceptable basis.'
        modalDescription='Please delete these purposes, or change their basis from "legitimate interest" to "consent".'
      />

      <DidomiModalActions>
        <DidomiButton type="button" variant="secondary" onClick={onClose}>
          I understand
        </DidomiButton>
      </DidomiModalActions>
    </DidomiModal>
  );
};
