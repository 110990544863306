import { getTranslatedValue } from '@didomi/helpers';
import { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import { useAxiosWithLogOut } from '@hooks';
import { PaginatedResponse, ServiceProvider } from '@types';

/**
 * Fetches the full list of service provider (aka vendor taxonomies)
 * */
export const useServiceProvider = () => {
  const axiosWithInterceptors = useAxiosWithLogOut();

  const fetchServiceProviders = async () => {
    try {
      const response = await axiosWithInterceptors.get<PaginatedResponse<ServiceProvider>>(`${CONFIG.environmentConfig.apiBaseUrl}taxonomies/vendors?$translations=true`);

      const data = response.data;
      const sortedItems = data.data.sort((a, b) => getTranslatedValue(a.name)?.localeCompare(getTranslatedValue(b.name)));

      return {
        ...data,
        data: sortedItems,
      };
    } catch (error) {
      throw new Error(error.message);
    }
  };

  return useQuery<PaginatedResponse<ServiceProvider>, AxiosError>(['service-providers'], fetchServiceProviders);
};
