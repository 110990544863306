import React from 'react';
import { DidomiButton } from '@didomi/ui-atoms-react';
import { tx } from '@twind/core';

interface SpiSelectionFiltersProps {
  allRegulations: string[];
  selectedRegulations: string[];
  onRegulationSelectionChange: (regulations: string[]) => void;
}

export const SpiSelectionFilters = ({ allRegulations = [], selectedRegulations = [], onRegulationSelectionChange }: SpiSelectionFiltersProps) => {
  const handleFilterOptionClick = (regulation: string) => {
    const updatedFilters = selectedRegulations.includes(regulation) ? selectedRegulations.filter(r => r !== regulation) : [...selectedRegulations, regulation];
    onRegulationSelectionChange(updatedFilters);
  };

  const areFiltersEmpty = selectedRegulations.length === 0;

  return (
    <div className={tx('flex flex-wrap items-center gap-xxs mb-xs justify-end')}>
      <div className={tx('font-semibold text-body-extra-small text-primary-blue-6')}>Filter by regulations</div>

      <DidomiButton
        variant={areFiltersEmpty ? 'option-filled' : 'option'}
        size="small"
        aria-label={areFiltersEmpty ? 'All - selected' : 'All'}
        onClick={() => onRegulationSelectionChange([])}
      >
        All
      </DidomiButton>
      {allRegulations.map(r => (
        <DidomiButton
          key={r}
          aria-label={selectedRegulations.includes(r) ? `${r} - selected` : r}
          variant={selectedRegulations.includes(r) ? 'option-filled' : 'option'}
          size="small"
          onClick={() => handleFilterOptionClick(r)}
        >
          {r.toUpperCase()}
        </DidomiButton>
      ))}
    </div>
  );
};
