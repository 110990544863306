import React from 'react';
import { DidomiTooltip } from '@didomi/ui-atoms-react';
import Highlighter from 'react-highlight-words';

type TextWithHighlighterAndTooltipProps = {
  text: string;
  searchText: string;
};

export const TextWithHighlighterAndTooltip = ({ text, searchText }: TextWithHighlighterAndTooltipProps) => {
  return (
    <DidomiTooltip content={text} onlyIfTruncated>
      <div className="truncate w-full !leading-[16px]">
        <Highlighter highlightClassName="text-primary-pink-4 bg-white" searchWords={[searchText]} textToHighlight={text} autoEscape />
      </div>
    </DidomiTooltip>
  );
};
