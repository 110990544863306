import { useActiveOrganization } from '@didomi/utility-react';
import { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import { CollectedVendors, VendorType } from '@types';
import { useAxiosWithLogOut } from './../../useAxiosWithInterceptors.hook';

export const useCollectedVendorsFromCr = ({ ids, unmatchedOnly, namespaces }) => {
  const { organizationId } = useActiveOrganization();
  const axiosWithInterceptors = useAxiosWithLogOut();
  const fetchVendorsFromReportIds = async (ids: string[], unmatchedOnly: boolean, namespaces: VendorType[]) => {
    if (ids) {
      const response = await axiosWithInterceptors.post<CollectedVendors[]>(
        `${CONFIG.environmentConfig.apiBaseUrl}agnostik/reports/collected-vendors?organization_id=${organizationId}&bypassMetadataPartnersMapping=false`,
        {
          namespaces,
          report_ids: ids,
          unmatchedDomainsOnly: unmatchedOnly,
        },
      );
      return response.data;
    }
  };
  const results = useQuery<CollectedVendors[], AxiosError>([`property-report-vendors`, ids], () => fetchVendorsFromReportIds(ids, unmatchedOnly, namespaces), {});

  return {
    ...results,
    isLoading: results.isLoading || results.isIdle,
  };
};
