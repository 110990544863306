import React, { useRef } from 'react';
import { supportedLanguages } from '@didomi/helpers';
import {
  DidomiModal,
  DidomiModalHeader,
  DidomiModalActions,
  DidomiButton,
  DidomiModalContent,
  DidomiSelectField,
  DidomiSelectOption,
  DidomiSelectOptions,
} from '@didomi/ui-atoms-react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { SanitizedTextInputField, SanitizedTextareaField } from '@components';
import { DataLanguage, LanguageForm } from '@types';

type AddPurposeLanguageModalProps = {
  isOpen: boolean;
  isEditing: boolean;
  languageList: DataLanguage[];
  disabledLanguages?: string[];
  initialValues: any;
  onConfirm: (newLanguage: LanguageForm) => void;
  onCancel: () => void;
  onChange: (e: CustomEvent) => void;
};

const PurposeLanguageSchema = Yup.object().shape({
  language: Yup.string().trim().required('Required'),
  description: Yup.string().trim().required('Required'),
  details: Yup.string().trim(),
});

export const AddPurposeLanguageModal = ({ isOpen, initialValues, isEditing, languageList, disabledLanguages = [], onCancel, onConfirm }: AddPurposeLanguageModalProps) => {
  const formikRef = useRef(null);

  return (
    <DidomiModal
      isOpen={isOpen}
      data-testid={isEditing ? 'edit-purpose-language-modal' : 'add-purpose-language-modal'}
      data-cy={isEditing ? 'edit-purpose-language-modal' : 'add-purpose-language-modal'}
      variant="slim"
      onOpenChange={
        /* istanbul ignore next - can't be tested with the mocks */ e => {
          if (!e.detail) {
            formikRef.current?.resetForm();
            onCancel();
          }
        }
      }
    >
      <Formik
        initialValues={initialValues}
        innerRef={formikRef}
        validationSchema={PurposeLanguageSchema}
        onSubmit={(values, { resetForm }) => {
          const mappedValues = {
            ...values,
            language: supportedLanguages.find(c => c.code === values.language),
          };
          resetForm();
          onConfirm(mappedValues);
        }}
        enableReinitialize
        validateOnMount={true}
      >
        {({ resetForm }) => (
          <Form
            style={{ width: '100%' }}
            data-testid={isEditing ? 'edit-purpose-language-form' : 'add-purpose-language-form'}
            data-cy={isEditing ? 'edit-purpose-language-form' : 'add-purpose-language-form'}
          >
            <DidomiModalHeader modalTitle={isEditing ? 'Update purpose' : 'Create a purpose'}></DidomiModalHeader>
            <DidomiModalContent style={{ marginTop: '24px' }}>
              <DidomiSelectField data-testid="add-language-select" name="language" required label="Language" placeholder="Choose a language in the list" disabled={isEditing}>
                <DidomiSelectOptions>
                  <DidomiSelectOption label="--" value="" variant="empty" />
                  {languageList.map(lang => (
                    <DidomiSelectOption key={lang.code} value={lang.code} label={lang.longName} disabled={disabledLanguages?.includes(lang.code)} />
                  ))}
                </DidomiSelectOptions>
              </DidomiSelectField>
              <SanitizedTextInputField name="description" label="Purpose name" placeholder="Add a purpose name" />
              <SanitizedTextareaField name="details" label="Purpose description" placeholder="Add a description" />
            </DidomiModalContent>
            <DidomiModalActions>
              <DidomiButton
                type="button"
                variant="secondary"
                onClick={() => {
                  resetForm();
                  onCancel();
                }}
              >
                Cancel
              </DidomiButton>
              <DidomiButton type="submit">
                <span>{isEditing ? 'Update' : 'Add'}</span>
              </DidomiButton>
            </DidomiModalActions>
          </Form>
        )}
      </Formik>
    </DidomiModal>
  );
};
