import { getTranslatedValue } from '@didomi/helpers';
import { VendorLinkType, VendorLink, Vendor, Purpose, CollectedVendors, VendorType } from '@types';

const getPurposeText = (dPID: string, purposes: Purpose[]) => getTranslatedValue(purposes?.find(p => p.id === dPID)?.description);

const getReadableLink = (links: VendorLink) =>
  links
    ? Object.keys(links)
        .map(typesOfLinks => ({
          label: VendorLinkType[typesOfLinks],
          value: links[typesOfLinks],
        }))
        .filter(l => !!l.value)
    : [];

export const mapVendorResult = (vendor: Vendor, purposes: Purpose[] = []) => ({
  ...vendor,
  default_purposes_name: vendor.default_purposes_id?.map(pId => getPurposeText(pId, purposes)).filter(Boolean),
  legitimate_interest_purposes_name: vendor.legitimate_interest_purposes_id?.map(pId => getPurposeText(pId, purposes)).filter(Boolean),
  links_readable: getReadableLink(vendor?.links),
});

export const hostPatterns = (hosts: string[]) => {
  return hosts.filter(Boolean).map(host => {
    const regexSafeHost = host.replace(/\./g, '\\.').replace(/\*/g, '.*');
    return new RegExp(`^${regexSafeHost}$`, 'i');
  });
};

// Return unknow vendors and models from rules:
// - vendors with no id or with functional_namespace = BUILT_IN and
// - vendors with existing url_host field
// - vendors that unmatched with any item from hosts array
export const getUnknownAndBuiltInVendors = (vendors: CollectedVendors[], hosts: string[]) => {
  let patterns = [];
  if (hosts.length > 0) {
    patterns = hostPatterns(hosts);
  }

  if (vendors) {
    return vendors
      .filter(vendor => !vendor.id || vendor.functional_namespace === VendorType.BUILT_IN)
      .filter(domain => !!domain.url_host)
      .filter(vendor => {
        return !patterns.some(pattern => pattern.test(vendor.url_host));
      }) as CollectedVendors[];
  }
  return [];
};
