import { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import { useAxiosWithLogOut } from '@hooks';
import { Purpose } from '@types';

/**
 * Fetches a particular purpose
 * */
export const usePurpose = (purposeId: string) => {
  const axiosWithInterceptors = useAxiosWithLogOut();

  const fetchPurpose = async () => {
    const response = await axiosWithInterceptors.get<Purpose>(`${CONFIG.environmentConfig.apiBaseUrl}metadata/purposes/${purposeId}?$translations=true`);

    return response.data;
  };

  return useQuery<Purpose, AxiosError>(['purpose', purposeId], fetchPurpose, {
    enabled: !!purposeId,
  });
};
