import React, { useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { getCounterText, getTranslatedValue, supportedLanguages } from '@didomi/helpers';
import { DidomiFiltersBarCustomEvent } from '@didomi/ui-atoms';
import {
  DidomiEmptyState,
  DidomiButton,
  DidomiIconButton,
  DidomiTable,
  DidomiTableHeading,
  DidomiTableHeaderRow,
  DidomiTableTh,
  DidomiTableBody,
  DidomiTableRow,
  DidomiTableTd,
  DidomiPaginator,
  DidomiSkeleton,
  DidomiHintbox,
  DidomiErrorState,
  DidomiTooltip,
  DidomiFiltersBar,
} from '@didomi/ui-atoms-react';
import { useSnackbar, useHasAccessPolicies } from '@didomi/utility-react';
import { ACCESS_POLICIES_CONFIG } from '@access';
import { TextWithHighlighterAndTooltip } from '@components';
import { usePurposes, usePaginationQueryParams, usePaginatedFilteredItems, useDeletePurpose } from '@hooks';
import { RemoveItemConfirmationModal, LoadingModal } from '@modals';
import { SortConfig, PurposeSortableFiled, Purpose } from '@types';

const SEARCH_FIELDS = ['id', 'sdk_id'];
const TRANSLATABLE_SEARCH_FIELDS = ['description', 'details'];

/**
 * Purposes Default Page
 */
export const Purposes = (): JSX.Element => {
  const [deletingPurpose, setDeletingPurpose] = useState<Purpose>(null);
  const [selectedLanguage, setSelectedLanguage] = useState<string>('');
  const navigate = useNavigate();
  const { displaySnackbar } = useSnackbar();
  const [sortConfig, setSortConfig] = useState<SortConfig<PurposeSortableFiled>>({ field: 'description', dir: 'asc' });
  const { isLoading, isRefetching, data: { data: purposes, total: totalPurposes } = {}, error: errorLoadingPurposes } = usePurposes();
  const { search, limit, page: currPage, setSearch, setLimit, setPage: setCurrPage } = usePaginationQueryParams({ maxItems: totalPurposes });
  const { hasAccess: canEdit } = useHasAccessPolicies(ACCESS_POLICIES_CONFIG.EDITOR);

  const { data: displayPurposes, total: paginationTotal } = usePaginatedFilteredItems<Purpose, PurposeSortableFiled>(purposes, {
    search,
    searchFields: SEARCH_FIELDS,
    translatableSearchFields: TRANSLATABLE_SEARCH_FIELDS,
    limit,
    page: currPage,
    sortConfig,
  });

  const { mutate: deletePurpose, isLoading: isDeletingPurpose } = useDeletePurpose({
    onSuccess: deletedPurpose => {
      setDeletingPurpose(null);
      displaySnackbar(`Purpose "${getTranslatedValue(deletedPurpose.data.description)}" has been deleted successfully!`, { icon: 'check' });
    },
    onError: () => {
      displaySnackbar('There was an error deleting the purpose', { icon: 'danger-light', variant: 'error' });
    },
  });

  const handleFilterUpdate = (newFilterChange: DidomiFiltersBarCustomEvent<{ key: string; newValue: string }>) => {
    const modifiedFilter = newFilterChange.detail.key;
    if (modifiedFilter === 'filterByTranslation') {
      setSelectedLanguage(newFilterChange.detail.newValue);
    }
  };

  const handleClearFilters = () => {
    setSearch('');
    setSelectedLanguage(undefined);
  };

  const noPurposes = !purposes?.length;
  const noDisplayPurposes = !displayPurposes?.length;
  const countText = getCounterText(purposes?.length || totalPurposes, 'result', 'results');
  const loadingPurposes = isLoading || isRefetching;

  const updateSorting = (e: CustomEvent) => {
    const newSortBy = e.detail.sortId;
    const newSortDir = newSortBy !== sortConfig.field ? 'desc' : e.detail.direction;
    setSortConfig({ dir: newSortDir, field: newSortBy ?? sortConfig.field });
  };

  const resultsText = `${paginationTotal < totalPurposes ? `${paginationTotal} of ` : ''}${countText}`;

  return (
    <>
      {!noPurposes && (
        <div className="w-full h-full pt-6 flex flex-col">
          {canEdit && (
            <DidomiHintbox iconName="warning" titleText="Important" variant="warning" className="mb-m">
              Here you can find the purposes that you have created in case you would like to amend them. You must state a specified, explicit and lawful purpose that data subjects
              will be able to understand. Keep it short, keep it clear and refer to our resources center for more details about purposes.
            </DidomiHintbox>
          )}

          <div className="flex justify-end flex-wrap-reverse gap-xs items-center mb-s">
            <DidomiFiltersBar
              data-testid="filter-bar"
              className="flex-1"
              leftText={resultsText}
              filters={{
                filterByTranslation: {
                  placeholder: 'View translation',
                  value: selectedLanguage,
                  options: supportedLanguages.map(l => ({
                    value: l.code,
                    label: l.longName,
                  })),
                },
              }}
              onClearAllFilters={handleClearFilters}
              onFilterValueChange={handleFilterUpdate}
              showSearch
              searchValue={search}
              collapsibleFilters={false}
              onSearchTextChange={setSearch}
            />

            {canEdit && (
              <DidomiButton data-tracking="add-crud-list-button" iconRight="new-create" onClick={() => navigate('/add-purpose')}>
                Add purpose
              </DidomiButton>
            )}
          </div>
          {!noDisplayPurposes && (
            <>
              <DidomiSkeleton variant="layout" isLoading={loadingPurposes}>
                <DidomiTable
                  data-testid={loadingPurposes ? 'loading-purposes-table' : 'purpose-table'}
                  data-cy={loadingPurposes ? 'loading-purposes-table' : 'purpose-table'}
                  fixedLayout
                  className="mb-4"
                  loading={loadingPurposes}
                  sortable
                  sortBy={sortConfig.field}
                  sortDirection={sortConfig.dir}
                  onSortChange={updateSorting}
                >
                  <DidomiTableHeading>
                    <DidomiTableHeaderRow>
                      <DidomiTableTh sortId="description" data-testid="sortName">
                        NAME
                      </DidomiTableTh>
                      <DidomiTableTh sortId="details" data-testid="sortDescription">
                        DESCRIPTION
                      </DidomiTableTh>
                      <DidomiTableTh sortId="id" data-testid="sortId">
                        APP ID
                      </DidomiTableTh>
                      <DidomiTableTh sortId="sdk_id" data-testid="sortSdkId">
                        SDK ID
                      </DidomiTableTh>
                      {canEdit && <DidomiTableTh cellAlign="right" style={{ maxWidth: 152 }}></DidomiTableTh>}
                    </DidomiTableHeaderRow>
                  </DidomiTableHeading>
                  <DidomiTableBody>
                    {displayPurposes?.map(({ id, description, details, sdk_id }, i) => (
                      <DidomiTableRow key={id} selectionValue={id} data-testid={id} data-cy={`purpose-row-${i}`}>
                        <DidomiTableTd>
                          <div data-skeleton={loadingPurposes} className="w-full">
                            <NavLink to={`/purpose/${id}`} className="underline !outline-none inline-block focus-visible:(ring-[3px] ring-primary-blue-2)">
                              <TextWithHighlighterAndTooltip searchText={search} text={getTranslatedValue(description, '-', selectedLanguage, !selectedLanguage)} />
                            </NavLink>
                          </div>
                        </DidomiTableTd>
                        <DidomiTableTd>
                          <div data-skeleton={loadingPurposes} className="truncate w-full">
                            <TextWithHighlighterAndTooltip searchText={search} text={getTranslatedValue(details, '-', selectedLanguage, !selectedLanguage)} />
                          </div>
                        </DidomiTableTd>
                        <DidomiTableTd>
                          <div data-skeleton={loadingPurposes} className="truncate w-full">
                            <TextWithHighlighterAndTooltip searchText={search} text={id || '-'} />
                          </div>
                        </DidomiTableTd>
                        <DidomiTableTd>
                          <div data-skeleton={loadingPurposes} className="truncate w-full">
                            <TextWithHighlighterAndTooltip searchText={search} text={sdk_id || '-'} />
                          </div>
                        </DidomiTableTd>
                        {canEdit && (
                          <DidomiTableTd cellAlign="right" style={{ maxWidth: 152 }}>
                            <div className="flex px-xxxs">
                              <DidomiTooltip content="Edit purpose" placement="top">
                                <DidomiIconButton
                                  data-tracking="edit-crud-list-action-button"
                                  type="button"
                                  title="Edit purpose"
                                  variant="rounded"
                                  icon="edit"
                                  className="mr-xxs"
                                  onClick={() => navigate(`/purpose/${id}`)}
                                />
                              </DidomiTooltip>
                              <DidomiTooltip content="Delete purpose" placement="top">
                                <DidomiIconButton
                                  data-tracking="delete-crud-list-action-button"
                                  type="button"
                                  title="Delete purpose"
                                  variant="rounded"
                                  icon="delete"
                                  onClick={() => setDeletingPurpose({ id, description, details, sdk_id })}
                                />
                              </DidomiTooltip>
                            </div>
                          </DidomiTableTd>
                        )}
                      </DidomiTableRow>
                    ))}
                  </DidomiTableBody>
                </DidomiTable>
              </DidomiSkeleton>
              <DidomiPaginator
                data-testid="purposes-paginator"
                className="self-end"
                page={currPage}
                itemCount={paginationTotal}
                size={limit}
                onPageSizeChange={setLimit}
                onPageChange={setCurrPage}
                disabled={loadingPurposes}
              />
            </>
          )}
          {noDisplayPurposes && (
            <DidomiEmptyState illustration-name="list-cannot-be-loaded" className="border-1 border-dashed border-neutral-gray-5 rounded-lg flex-1 mb-s">
              No results
            </DidomiEmptyState>
          )}
        </div>
      )}
      {noPurposes && errorLoadingPurposes && (
        <div className="w-full h-full mt-s">
          <DidomiErrorState illustration-name="list-cannot-be-loaded" className="h-full border-1 border-dashed border-neutral-gray-5 rounded-lg flex-1">
            <div slot="title">There was an error loading the purpose list</div>
          </DidomiErrorState>
        </div>
      )}
      {noPurposes && !isLoading && !errorLoadingPurposes && (
        <div className="w-full h-full mt-s">
          <DidomiEmptyState
            illustration-name="no-widget-yet"
            className="h-full border-1 border-dashed border-neutral-gray-5 rounded-lg py-8"
            actionName="Add purpose"
            onActionClick={() => navigate(`/add-purpose`)}
          >
            <div slot="title">You have no purposes yet</div>
          </DidomiEmptyState>
        </div>
      )}
      <RemoveItemConfirmationModal
        itemName={getTranslatedValue(deletingPurpose?.description)}
        isOpen={!!deletingPurpose && !isDeletingPurpose}
        onCancel={() => setDeletingPurpose(null)}
        onProceed={() => deletePurpose(deletingPurpose.id)}
      />
      <LoadingModal data-cy="add-purpose-saving" isOpen={isDeletingPurpose} title="We are deleting your purpose..." />
    </>
  );
};
