import React from 'react';
import { NavLink } from 'react-router-dom';
import {
  DidomiFeatureHeader,
  DidomiNavTabs,
  DidomiNavTabHeading,
  DidomiNavTabHeader,
  DidomiNavTabHeaders,
  DidomiNavTabContents,
  DidomiNavTabContent,
  DidomiSkeleton,
} from '@didomi/ui-atoms-react';
import { tx } from '@twind/core';

interface IMainLayoutProps {
  children: React.ReactChild;
  loading?: boolean;
  disableWidgetsTab?: boolean;
  Action?: React.ComponentType<any>;
}

/**
 * Main Layout
 * Default layout with header and tab navs for second level pages
 */
const MainLayout = ({ children, loading, disableWidgetsTab, Action }: IMainLayoutProps): JSX.Element => (
  <section className="p-12 pb-unsafe-bottom-space min-h-full box-border !flex flex-col">
    <DidomiSkeleton isLoading={loading} variant="layout" className="flex-1 flex flex-col">
      <div className="flex mb-8">
        <DidomiFeatureHeader titleText="Data Manager" className="w-full" style={{ display: 'block' }}>
          <div slot="description" data-cy="data-manager-header">
            Manage the purposes of your data collection in order to be compliant with GDPR and declare the vendors you want to share the data with.
          </div>
          <div slot="actions" data-skeleton>
            {Action && <Action />}
          </div>
        </DidomiFeatureHeader>
      </div>
      <DidomiNavTabs className="flex-1 flex flex-col">
        <DidomiNavTabHeading data-skeleton className={tx({ '!border-0': loading })}>
          <DidomiNavTabHeaders>
            <DidomiNavTabHeader disabled={disableWidgetsTab}>
              <NavLink data-testid="nav-link-purposes" className={navData => (navData.isActive ? 'didomi-active-tab' : '')} data-cy="purposes" to="/purposes">
                Purposes
              </NavLink>
            </DidomiNavTabHeader>

            <DidomiNavTabHeader>
              <NavLink data-testid="nav-link-vendors" className={navData => (navData.isActive ? 'didomi-active-tab' : '')} data-cy="vendors" to="/vendors">
                Vendors
              </NavLink>
            </DidomiNavTabHeader>
          </DidomiNavTabHeaders>
        </DidomiNavTabHeading>
        <DidomiNavTabContents data-skeleton className={tx('flex-1 flex flex-col', { 'mt-s': loading })}>
          {!loading && <DidomiNavTabContent className="flex-1">{children}</DidomiNavTabContent>}
        </DidomiNavTabContents>
      </DidomiNavTabs>
    </DidomiSkeleton>
  </section>
);

export { MainLayout };
