import { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import { useAxiosWithLogOut } from '@hooks';
import { PaginatedResponse, Vendor } from '@types';

export interface IABTCFVendor extends Vendor {
  iab_tcf_vendor_id: number | null;
}

export const iabTCFNamespaces = ['iab', 'iab2'];

function extractIABTCFVendorID(vendor: Vendor): number | null {
  return vendor.namespaces?.iab || vendor.namespaces?.iab2 || null;
}

/**
 * Fetches the full (max 5000) list of IAB TCF vendors.
 * */
export const useIABTCFVendors = () => {
  const axiosWithInterceptors = useAxiosWithLogOut();

  const fetchIABTCFVendors = async () => {
    try {
      const response = await axiosWithInterceptors.get<PaginatedResponse<Vendor>>(
        `${CONFIG.environmentConfig.apiBaseUrl}metadata/partners?$only_iab_tcf_vendors=true$translations=true&$limit=5000`,
      );

      const data = response.data;
      const iabTCFVendors = data.data
        .map(vendor => {
          const iabTCFVendorID = extractIABTCFVendorID(vendor);
          return { ...vendor, iab_tcf_vendor_id: iabTCFVendorID };
        })
        .filter(vendor => vendor.iab_tcf_vendor_id);

      return {
        ...data,
        data: iabTCFVendors,
        total: iabTCFVendors.length,
      };
    } catch (error) {
      throw new Error(error.message);
    }
  };

  return useQuery<PaginatedResponse<IABTCFVendor>, AxiosError>(['iabTCFVendors'], fetchIABTCFVendors);
};
