import React from 'react';
import { DidomiTextareaField } from '@didomi/ui-atoms-react';
import { useSanitizedField } from '@hooks';

// This component is a wrapper around the DidomiTextareaField component that sanitizes the input value when it is changes
export const SanitizedTextareaField = (props: React.ComponentProps<typeof DidomiTextareaField>) => {
  const { sanitizeValueOnChange } = useSanitizedField(props.name, props.onValueChange);

  return <DidomiTextareaField {...props} onValueChange={sanitizeValueOnChange} />;
};
