import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';
import { useAxiosWithLogOut, useRegulationReleaseStatusConfig } from '@hooks';
import { PaginatedResponse, Regulation, RegulationReleaseStatus } from '@types';

/*
 * Fetches the full list of regulations
 */
export const useRegulations = (releaseStatus?: RegulationReleaseStatus, options?: UseQueryOptions<Regulation[]>) => {
  const axiosWithInterceptors = useAxiosWithLogOut();
  const { releaseStatusConfig } = useRegulationReleaseStatusConfig();

  const isRegulationVisible = (regulation: Regulation) => releaseStatusConfig[regulation.release_status] === 'visible';

  const fetchRegulations = async () => {
    try {
      const url = new URL(`${CONFIG.environmentConfig.apiBaseUrl}compliance/v1/regulations`);
      releaseStatus && url.searchParams.append(`$search[releaseStatus]`, releaseStatus);
      const response = await axiosWithInterceptors.get<PaginatedResponse<Regulation>>(url.toString());

      return response.data.data.filter(isRegulationVisible);
    } catch (error) {
      throw new Error(error.message);
    }
  };

  return useQuery<Regulation[], AxiosError>(['regulations', releaseStatus], fetchRegulations, {
    ...options,
    enabled: options?.enabled,
  });
};
