import React, { useRef } from 'react';
import {
  DidomiModal,
  DidomiModalHeader,
  DidomiModalActions,
  DidomiButton,
  DidomiModalContent,
  DidomiSelectField,
  DidomiSelectOption,
  DidomiSelectOptions,
} from '@didomi/ui-atoms-react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { SanitizedTextInputField } from '@components';
import { DataLanguage } from '@types';

type AddVendorContactModalProps = {
  isOpen: boolean;
  isEditing: boolean;
  initialValues: any;
  supportedLanguages: DataLanguage[];
  selectedLanguages: string[];
  onConfirm: (newCookieStorage) => void;
  onCancel: () => void;
  onChange: (e: CustomEvent) => void;
};

const VendorContactSchema = Yup.object().shape({
  language: Yup.string().required('Required'),
  contactEmail: Yup.string().trim().required('Required'),
  address: Yup.string().trim(),
});

export const AddVendorContactModal = ({ isOpen, initialValues, supportedLanguages, selectedLanguages, isEditing, onCancel, onConfirm }: AddVendorContactModalProps) => {
  const formikRef = useRef(null);

  return (
    <DidomiModal
      isOpen={isOpen}
      variant="slim"
      data-testid={isEditing ? 'update-vendor-contact-modal' : 'add-vendor-contact-modal'}
      data-cy={isEditing ? 'update-vendor-contact-modal' : 'add-vendor-contact-modal'}
      onOpenChange={
        /* istanbul ignore next - can't be tested with the mocks */ e => {
          if (!e.detail) {
            formikRef.current?.resetForm();
            onCancel();
          }
        }
      }
    >
      <Formik
        initialValues={initialValues}
        innerRef={formikRef}
        validationSchema={VendorContactSchema}
        onSubmit={(values, { resetForm }) => {
          resetForm();
          onConfirm(values);
        }}
        enableReinitialize
        validateOnMount={true}
      >
        {({ resetForm }) => (
          <Form
            style={{ width: '100%' }}
            data-testid={isEditing ? 'update-vendor-contact-form' : 'add-vendor-contact-form'}
            data-cy={isEditing ? 'update-vendor-contact-form' : 'add-vendor-contact-form'}
          >
            <DidomiModalHeader modalTitle={isEditing ? 'Update address' : 'Add a new address'}></DidomiModalHeader>
            <DidomiModalContent style={{ marginTop: '24px' }}>
              <DidomiSelectField data-testid="contact-language-select" name="language" label="Language *" placeholder="Select a language">
                <DidomiSelectOptions>
                  <DidomiSelectOption label="--" value="" variant="empty" />
                  {supportedLanguages.map(lang => (
                    <DidomiSelectOption key={lang.code} value={lang.code} label={lang.longName} disabled={selectedLanguages?.includes(lang.code)} />
                  ))}
                </DidomiSelectOptions>
              </DidomiSelectField>

              <SanitizedTextInputField name="contactEmail" label="Contact (E-mail of the DPO if any) *" placeholder="Enter a contact" />

              <SanitizedTextInputField name="address" label="Address" placeholder="Enter an address" />
            </DidomiModalContent>
            <DidomiModalActions>
              <DidomiButton
                type="button"
                variant="secondary"
                onClick={() => {
                  resetForm();
                  onCancel();
                }}
              >
                Cancel
              </DidomiButton>
              <DidomiButton type="submit">
                <span>{isEditing ? 'Update' : 'Add'}</span>
              </DidomiButton>
            </DidomiModalActions>
          </Form>
        )}
      </Formik>
    </DidomiModal>
  );
};
