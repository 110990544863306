import React, { useState } from 'react';
import { DidomiChip, DidomiIconButton, DidomiButton } from '@didomi/ui-atoms-react';
import { tx } from '@twind/core';
import isValidDomain from 'is-valid-domain';
import { SanitizedBasedInput } from '../SanitizedBasedInput/SanitizedBasedInput';

interface Iprops {
  onSelectionChange: (selectedDomains) => void;
  hosts: string[];
  onRedirect: Function;
}
export const VendorDomainFromInput = ({ onSelectionChange, hosts = [], onRedirect }: Iprops) => {
  const [targetInputValue, setTargetInputValue] = useState('');
  const [targetInputError, setTargetInputError] = useState('');

  const handleAddTarget = (event: React.KeyboardEvent<HTMLDidomiBaseInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();
      addTarget();
    }
  };

  const addTarget = () => {
    const newTargetUrlWithoutSchema = targetInputValue
      .trim()
      .toLocaleLowerCase()
      .replace(/https?:\/\//i, '');

    if (hosts.includes(newTargetUrlWithoutSchema)) {
      return setTargetInputError('This domain is already added');
    }

    if (!isValidDomain(newTargetUrlWithoutSchema)) {
      const errorText = newTargetUrlWithoutSchema.includes('/') ? 'Subpaths are not allowed' : 'Invalid domain';
      return setTargetInputError(errorText);
    }

    // avoid n-1 array
    const domainArray = [...hosts, newTargetUrlWithoutSchema];
    onSelectionChange(domainArray);
    setTargetInputError('');
    setTargetInputValue('');
  };

  const removeTarget = (target: string) => {
    const currenTargets = hosts;
    const newTargets = currenTargets.filter(t => t !== target);
    onSelectionChange(newTargets);
  };

  return (
    <div>
      <div className="flex justify-between items-center">
        <SanitizedBasedInput
          className="basis pr-s"
          value={targetInputValue}
          label="Domains owned by the vendors"
          onValueChange={e => {
            setTargetInputError('');
            setTargetInputValue(e.detail);
          }}
          title="Target domain"
          aria-label="Target domain"
          data-testid="target-domain"
          error={targetInputError}
          onKeyDown={/* istanbul ignore next */ e => handleAddTarget(e)}
          placeholder={'http://www...'}
        >
          <DidomiIconButton
            data-testid="Add target"
            title="Add target"
            size="small"
            slot="right"
            icon="new-create"
            onClick={addTarget}
            disabled={!!targetInputError || !targetInputValue}
          ></DidomiIconButton>
        </SanitizedBasedInput>
        {/* Fix to align button with input */}
        <DidomiButton style={{ marginTop: '-3px' }} variant="secondary" onClick={() => onRedirect()}>
          Show unmatched vendors domains
        </DidomiButton>
      </div>

      <div className={tx('flex flex-wrap gap-xxxs', { '-mt-xs': !targetInputError })}>
        {hosts?.length > 0
          ? hosts.map(target => (
              <DidomiChip data-testId={target} variation="basic-outline" key={target} label={target} rightIcon="close-sm" onRightIconClick={() => removeTarget(target)} />
            ))
          : ''}
      </div>
    </div>
  );
};
