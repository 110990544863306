import React, { useState } from 'react';
import { supportedLanguages } from '@didomi/helpers';
import { DidomiButton } from '@didomi/ui-atoms-react';
import { useField } from 'formik';
import { ContactCard } from '@components';
import { AddVendorContactModal } from '@modals';

type VendorContactFieldProps = {
  name: string;
  isLoading?: boolean;
  className?: string;
};

const emptyVendorContact = { language: '', contactEmail: '', address: '' };

export const VendorContactField = ({ name, isLoading, className }: VendorContactFieldProps): JSX.Element => {
  const [, { value: contacts, error, touched }, { setValue, setTouched }] = useField({ name });
  const [displayAddVendorContactModal, setDisplayAddVendorContactModal] = useState<boolean>(false);
  const [addVendorContactInitialValues, setAddVendorContactInitialValues] = useState(emptyVendorContact);
  const [editingVendorContact, setEditingVendorContact] = useState<number>(null);

  const selectedLanguages = contacts ? contacts.map(c => c.language) : [];

  const handleAddVendorContact = (newVendorContact: any) => {
    if (editingVendorContact !== null) {
      setValue(
        contacts?.map((v, i) => {
          if (i === editingVendorContact) {
            return newVendorContact;
          } else {
            return v;
          }
        }),
      );
      setEditingVendorContact(null);
    } else {
      setValue(contacts?.length ? contacts.concat(newVendorContact) : [newVendorContact]);
    }
    setDisplayAddVendorContactModal(false);
  };

  const handleRemoveVendorContact = (languageCode: string) => {
    const newValue = contacts?.filter(v => v?.language !== languageCode);
    setValue(newValue);
  };

  const handleEditLanguage = (cookieVendor: any, vendorCookieIndex: number) => {
    setEditingVendorContact(vendorCookieIndex);
    setAddVendorContactInitialValues(cookieVendor);
    setDisplayAddVendorContactModal(true);
  };

  const showAddVendorContactModal = () => {
    setEditingVendorContact(null);
    setAddVendorContactInitialValues(emptyVendorContact);
    setDisplayAddVendorContactModal(true);
  };

  const hideAddVendorContactModal = () => {
    setTouched(true);
    setDisplayAddVendorContactModal(false);
  };

  return (
    <div className={className}>
      {contacts?.map((c, i) => (
        <ContactCard
          key={'contact-' + i}
          data-testid={'contact-card-' + c.language}
          className="mb-s"
          language={supportedLanguages.find(l => l.code === c.language)}
          isLoading={isLoading}
          contactEmail={c.contactEmail}
          address={c.address}
          onEdit={() => handleEditLanguage(c, i)}
          onRemove={() => handleRemoveVendorContact(c.language)}
        />
      ))}

      <div className="w-full flex justify-center">
        <DidomiButton type="button" size="small" onClick={() => showAddVendorContactModal()}>
          Add address
        </DidomiButton>
      </div>

      <AddVendorContactModal
        isOpen={displayAddVendorContactModal}
        initialValues={addVendorContactInitialValues}
        isEditing={editingVendorContact !== null}
        supportedLanguages={supportedLanguages}
        selectedLanguages={selectedLanguages}
        onConfirm={handleAddVendorContact}
        onCancel={hideAddVendorContactModal}
        onChange={
          /* istanbul ignore next - can't be tested with the mocks */ e => {
            if (!e.detail) hideAddVendorContactModal();
          }
        }
      />

      {error && touched && <div className="text-secondary-red-4 font-sans text-[12px] mt-xxxs">{error}</div>}
    </div>
  );
};
