import React from 'react';
import { DidomiHintbox, DidomiAutocompleteWithSearchField, DidomiCollapsibleSection } from '@didomi/ui-atoms-react';
import { useIABTCFVendors } from '@hooks';

type IABTCFVendorOverrideFieldProps = {
  name: string;
};

export const IABTCFVendorOverrideField = ({ name }: IABTCFVendorOverrideFieldProps): JSX.Element => {
  const { data: iabTCFVendors, isLoading: loadingIABTCFVendors } = useIABTCFVendors();

  return (
    <DidomiCollapsibleSection data-testid="iab-tcf-vendor-override-collapsible-section" label="Vendor override" className="mb-m">
      <DidomiHintbox variant="neutral" className="mt-xxs mb-xs">
        This vendor can override an IAB TCF vendor selected in the below list. In this case the consent status for the selected IAB vendor will be calculated based on custom
        purposes instead of IAB purposes.
      </DidomiHintbox>
      <DidomiAutocompleteWithSearchField
        data-testid="iab-tcf-vendor-override-search"
        name={name}
        label="IAB TCF vendor"
        placeholder="Select an IAB TCF vendor"
        data-skeleton={loadingIABTCFVendors}
        items={iabTCFVendors?.data}
        valueField="iab_tcf_vendor_id"
      />
    </DidomiCollapsibleSection>
  );
};
