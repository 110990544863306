import { useState, useEffect } from 'react';
import { createWorkerFactory, useWorker } from '@shopify/react-web-worker';
import { SortConfig } from '@types';

type PaginateAndSearchOptions<T> = {
  search: string;
  searchFields: string[];
  translatableSearchFields: string[];
  limit: number;
  page: number;
  sortConfig: SortConfig<T>;
};

const createSearchPurposesWorker = createWorkerFactory(() => import('@workers'));

export const usePaginatedFilteredItems = <T, Z>(items: T[], options: PaginateAndSearchOptions<Z>) => {
  const [displayItems, setDisplayItems] = useState<T[]>([]);
  const [filteredItems, setFilteredItems] = useState<T[]>([]);
  const [paginationTotal, setPaginationTotal] = useState<number>(10);
  const searchItemsWorker = useWorker(createSearchPurposesWorker);
  const { search, searchFields, translatableSearchFields, page, limit, sortConfig } = options;

  useEffect(() => {
    if (!items?.length) return;
    (async () => {
      const { data, total, totalItems } = (await searchItemsWorker.paginateAndSearchItems(items, {
        search: search || '',
        searchFields: searchFields || [],
        translatableSearchFields,
        page,
        limit,
        sortConfig,
      })) as { data: T[]; total: number; totalItems: T[] };
      setDisplayItems(data);
      setPaginationTotal(total);
      setFilteredItems(totalItems);
    })();
  }, [searchItemsWorker, items, search, searchFields, translatableSearchFields, page, limit, sortConfig]);

  return {
    data: displayItems,
    total: paginationTotal,
    totalItems: filteredItems,
  };
};
