import { useActiveOrganization } from '@didomi/utility-react';
import { AxiosError, AxiosResponse } from 'axios';
import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import { useAxiosWithLogOut, useAllPurposes } from '@hooks';
import { PaginatedResponse, VendorRequestBody, Vendor } from '@types';
import { mapVendorResult } from '@utils';

/**
 * Edits a vendor, makes sure to update the list of vendors with the edited vendor
 * */
export const useEditVendor = (vendorId: string, options?: UseMutationOptions<AxiosResponse<Vendor>, AxiosError, VendorRequestBody>) => {
  const axiosWithInterceptors = useAxiosWithLogOut();
  const { organizationId } = useActiveOrganization();
  const queryClient = useQueryClient();

  const { data: purposes } = useAllPurposes();

  return useMutation(
    async (vendor: VendorRequestBody) => {
      return axiosWithInterceptors.patch(`${CONFIG.environmentConfig.apiBaseUrl}metadata/partners/${vendorId}?$translations=true`, vendor);
    },
    {
      ...options,
      onSuccess: (editedVendor: AxiosResponse<Vendor>, variables, context) => {
        const editedVendorMapped = mapVendorResult(editedVendor.data, purposes?.data);

        const vendorsGet: PaginatedResponse<Vendor> = queryClient.getQueryData(['vendors', organizationId]);
        /* istanbul ignore if */
        if (vendorsGet) {
          queryClient.setQueryData(['vendors', organizationId], {
            ...vendorsGet,
            data: vendorsGet.data.map(vendor => (vendor.id === vendorId ? editedVendorMapped : vendor)),
            total: vendorsGet.total + 1,
          });
        }

        const vendorGet: PaginatedResponse<Vendor> = queryClient.getQueryData(['vendor', vendorId]);

        /* istanbul ignore if */
        if (vendorGet) {
          queryClient.setQueryData(['vendor', vendorId], editedVendorMapped);
        }

        if (options?.onSuccess) {
          options.onSuccess(editedVendor, variables, context);
        }
      },
    },
  );
};
