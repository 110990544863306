// Define on which vendorm form we are and use according session stored data if it exsist
// This avoid the vendor form to reset fields if the user change page
// matchingVendorHost => case where we are creating a vendor from a CMP Vendors sync (SVL) unknow host
// vendorId => case where we are updating a vendor on the /vendor/:vendorId url
// 'add-vendor' => case where we are creating a vendor from nothing
// Flush the session storage if we are out of those cases
export const handleFormDataFromStorage = ({ storedDraftVendorFormData, matchingVendorHost, vendorId, setMutableInitialValues, setStoredDraftVendorFormData }) => {
  const key = storedDraftVendorFormData?.key;

  switch (key) {
    case matchingVendorHost:
      if (matchingVendorHost) setMutableInitialValues(storedDraftVendorFormData.vendor);
      break;
    case vendorId:
      if (vendorId) setMutableInitialValues(storedDraftVendorFormData.vendor);
      break;
    case 'add-vendor':
      if (!matchingVendorHost && !vendorId) setMutableInitialValues(storedDraftVendorFormData.vendor);
      break;
    default:
      setStoredDraftVendorFormData(null);
  }
};
