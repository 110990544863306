import { useActiveOrganization } from '@didomi/utility-react';
import { AxiosError, AxiosResponse } from 'axios';
import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import { useAxiosWithLogOut } from '@hooks';
import { PaginatedResponse, Purpose, PurposeRequestBody } from '@types';

/**
 * Edits a purpose, makes sure to update the list of purposes with the edited purpose
 * */
export const useEditPurpose = (purposeId: string, options?: UseMutationOptions<AxiosResponse<Purpose>, AxiosError, PurposeRequestBody>) => {
  const axiosWithInterceptors = useAxiosWithLogOut();
  const { organizationId } = useActiveOrganization();
  const queryClient = useQueryClient();

  return useMutation(
    async (purpose: PurposeRequestBody) => {
      const requestBody = {
        ...purpose,
        organization_id: organizationId,
      };
      return axiosWithInterceptors.patch(`${CONFIG.environmentConfig.apiBaseUrl}metadata/purposes/${purposeId}?$translations=true`, requestBody);
    },
    {
      ...options,
      onSuccess: (editedPurpose: AxiosResponse<Purpose>, variables, context) => {
        const purposesGet: PaginatedResponse<Purpose> = queryClient.getQueryData(['purposes', organizationId]);
        /* istanbul ignore if */
        if (purposesGet) {
          queryClient.setQueryData(['purposes', organizationId], {
            ...purposesGet,
            data: purposesGet.data.map(purpose => (purpose.id === purposeId ? editedPurpose.data : purpose)),
            total: purposesGet.total + 1,
          });
        }

        const allPurposesGet: PaginatedResponse<Purpose> = queryClient.getQueryData(['purposes']);
        /* istanbul ignore if */
        if (allPurposesGet) {
          queryClient.setQueryData(['purposes'], {
            ...allPurposesGet,
            data: allPurposesGet.data.map(purpose => (purpose.id === purposeId ? editedPurpose.data : purpose)),
            total: allPurposesGet.total + 1,
          });
        }

        const purposeGet: PaginatedResponse<Purpose> = queryClient.getQueryData(['purpose', purposeId]);

        /* istanbul ignore if */
        if (purposeGet) {
          queryClient.setQueryData(['purpose', purposeId], editedPurpose.data);
        }

        if (options?.onSuccess) {
          options.onSuccess(editedPurpose, variables, context);
        }
      },
    },
  );
};
