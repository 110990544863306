import { useActiveOrganization } from '@didomi/utility-react';
import { AxiosError, AxiosResponse } from 'axios';
import { useMutation, UseMutationOptions } from 'react-query';
import { useAxiosWithLogOut } from '@hooks';

/**
 * Forward the metadata-partners update/create/delete signals to the CR API
 * to keep an up-to-date partners repository copy without stale data.
 * This is a solution to mitigate the 1~hour latency related to the Platform Stream sync.
 * */
export const useSyncDeletedVendorWithComplianceReport = (options?: UseMutationOptions<AxiosResponse<string>, AxiosError, string>) => {
  const axiosWithInterceptors = useAxiosWithLogOut();
  const { organizationId } = useActiveOrganization();

  return useMutation(
    async vendorId => {
      // Ensure vendorId is always a string
      return axiosWithInterceptors.delete(`${CONFIG.environmentConfig.apiBaseUrl}agnostik/metadata-partners/${vendorId}?organization_id=${organizationId}`);
    },
    {
      ...options,
    },
  );
};
