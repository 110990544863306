import { useCallback, useEffect, useState } from 'react';
import { useDebounce } from '@didomi/helpers-react';
import { PageLimitType, SortConfig } from '@types';

const DEFAULT_VALUE_PAGE = 1;
const DEFAULT_VALUE_LIMIT = 10 as PageLimitType;
const DEFAULT_SORT_CONFIG: SortConfig<unknown> = { field: 'name', dir: 'asc' };
const SEARCH_DEBOUNCE_TIME = 300;

interface TableStateOptions<T> {
  defaultLimit?: PageLimitType;
  defaultSorting?: SortConfig<T>;
  defaultFilters?: Record<string, unknown>;
}
export interface TableStateReturn<T> {
  limit: PageLimitType;
  page: number;
  sortConfig: SortConfig<T>;
  search: string;
  filters: Record<string, unknown> | undefined;
  changeLimit: (ev: CustomEvent<{ limit: number }>) => void;
  changePage: (ev: CustomEvent<{ page: number }>) => void;
  changeSorting: (ev: CustomEvent<{ sortId: string; direction: 'asc' | 'desc' }>) => void;
  changeSearch: (event: CustomEvent<void> | string) => void | string;
  changeFilters: (newFilters: Record<string, unknown>) => void;
}

export const useTableState = <T>(options?: TableStateOptions<T>): TableStateReturn<T> => {
  const [limit, setLimit] = useState(options?.defaultLimit || DEFAULT_VALUE_LIMIT);
  const [page, setPage] = useState(DEFAULT_VALUE_PAGE);
  const [sortConfig, setSortConfig] = useState(options?.defaultSorting || (DEFAULT_SORT_CONFIG as SortConfig<T>));
  const [filters, setFilters] = useState(options?.defaultFilters);
  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce(search, SEARCH_DEBOUNCE_TIME);

  // Reset page when table state changes: page size(limit), sorting, filters, search
  useEffect(() => {
    setPage(1);
  }, [limit, sortConfig, filters, debouncedSearch, setPage]);

  const changeLimit = useCallback(
    (ev: CustomEvent<{ limit: number }>) => {
      setLimit(ev.detail.limit as PageLimitType);
    },
    [setLimit],
  );

  const changePage = useCallback((ev: CustomEvent<{ page: number }>) => setPage(ev.detail.page), [setPage]);

  const changeSorting = useCallback(
    (ev: CustomEvent<{ sortId: string; direction: 'asc' | 'desc' }>) => {
      const newSortBy = ev.detail.sortId as unknown as T;
      const newSortDir = newSortBy !== sortConfig.field ? 'desc' : ev.detail.direction;

      setSortConfig({ dir: newSortDir, field: newSortBy });
    },
    [sortConfig, setSortConfig],
  );

  const changeSearch = useCallback(
    searchValue => {
      setSearch(searchValue);
    },
    [setSearch],
  );

  const changeFilters = useCallback(newFilters => setFilters(newFilters), [setFilters]);

  return {
    limit,
    page,
    sortConfig,
    search: debouncedSearch,
    filters,
    changeLimit,
    changePage,
    changeSorting,
    changeSearch,
    changeFilters,
  };
};
