import { useActiveOrganization } from '@didomi/utility-react';
import { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import { useAllPurposes, useAxiosWithLogOut } from '@hooks';
import { PaginatedResponse, Vendor } from '@types';
import { mapVendorResult } from '@utils';

/**
 * Fetches the full (max 5000) list of vendors corresponding to the loaded organization
 *
 * We have created a dependency on the usePurpose hook, as we want the purposes to be loaded first so
 * we can then map the purposes values with the corresponding names
 *
 * Note that the following fields are added for display in the browser:
 *
 * default_purposes_name: A mapped array of the name of default purposes
 * legitimate_interest_purposes_name: A mapped array of the name of legitimate interest purposes
 * links_readable: A mapped array of the list of websites
 *
 * */
export const useVendors = () => {
  const { organizationId } = useActiveOrganization();
  const axiosWithInterceptors = useAxiosWithLogOut();

  const { data: purposes, isFetched, isLoading } = useAllPurposes();

  const fetchVendors = async () => {
    try {
      const response = await axiosWithInterceptors.get<PaginatedResponse<Vendor>>(
        `${CONFIG.environmentConfig.apiBaseUrl}metadata/partners?$translations=true&$limit=5000&organization_id=${organizationId}`,
      );

      const data = response.data;
      const mappedVendors: Vendor[] = data.data.map(v => mapVendorResult(v, purposes.data));

      return {
        ...data,
        data: mappedVendors,
      };
    } catch (error) {
      throw new Error(error.message);
    }
  };

  const result = useQuery<PaginatedResponse<Vendor>, AxiosError>(['vendors', organizationId], fetchVendors, {
    enabled: isFetched,
  });

  return {
    ...result,
    isLoading: result.isLoading || isLoading,
  };
};
