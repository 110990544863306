import React from 'react';
import { DidomiBaseInput } from '@didomi/ui-atoms-react';
import { sanitizeHTML } from '@didomi/xss';

// This component is a wrapper around the DidomiBaseInput component that sanitizes the input value when it is changes
export const SanitizedBasedInput = (props: React.ComponentProps<typeof DidomiBaseInput>) => {
  const handleValueChange = e => {
    const sanitizedValue = sanitizeHTML(e.detail).result;
    props?.onValueChange?.({ ...e, detail: sanitizedValue });
  };

  return <DidomiBaseInput {...props} onValueChange={handleValueChange} />;
};
