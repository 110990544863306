import { useCustomFeature } from '@didomi/utility-react';
import { sanitizeHTML } from '@didomi/xss';
import { useField } from 'formik';

/**
 * Hook to sanitize the value of a formik field and call the original onValueChange with the sanitized value
 * The sanitisation is skipped if `disable-data-manager-xss-sanitization` custom flag is set
 * @example:
 * const { sanitizeValueOnChange } = useSanitizedField('notice', onValueChange);
 * <DidomiTextInputField name="notice" onValueChange={sanitizeValueOnChange} />
 */
export const useSanitizedField = (fieldName: string, originalOnValueChange?: (e) => void) => {
  const [isSanitizationDisabled] = useCustomFeature('disable-data-manager-xss-sanitization');
  const [, , { setValue }] = useField(fieldName);

  const sanitizeValueOnChange = e => {
    const value = isSanitizationDisabled ? e.detail : sanitizeHTML(e.detail).result;

    setValue(value);
    originalOnValueChange?.({ ...e, detail: value });
  };

  return { sanitizeValueOnChange };
};
