import React from 'react';
import { DidomiTextInputField } from '@didomi/ui-atoms-react';
import { useSanitizedField } from '@hooks';

// This component is a wrapper around the DidomiTextInputField component that sanitizes the input value when it is changes
export const SanitizedTextInputField = (props: React.ComponentProps<typeof DidomiTextInputField>) => {
  const { sanitizeValueOnChange } = useSanitizedField(props.name, props.onValueChange);

  return <DidomiTextInputField {...props} onValueChange={sanitizeValueOnChange} />;
};
