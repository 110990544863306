import { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import { useAxiosWithLogOut } from '@hooks';
import { PaginatedResponse, PartnersCategory } from '@types';

/**
 * Fetches the full list of supported partners categories
 * */
export const usePartnerCategories = () => {
  const axiosWithInterceptors = useAxiosWithLogOut();

  const fetchPartnerCategories = async () => {
    try {
      const response = await axiosWithInterceptors.get<PaginatedResponse<PartnersCategory>>(`${CONFIG.environmentConfig.apiBaseUrl}metadata/partners-categories`);

      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  return useQuery<PaginatedResponse<PartnersCategory>, AxiosError>(['partners-categories'], fetchPartnerCategories);
};
