import { useActiveOrganization } from '@didomi/utility-react';
import { AxiosError, AxiosResponse } from 'axios';
import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import { useAxiosWithLogOut } from '@hooks';
import { PaginatedResponse, Purpose, PurposeRequestBody } from '@types';

/**
 * Creates a purpose, makes sure to update the list of purposes with the new added purpose
 * */
export const useCreatePurpose = (options?: UseMutationOptions<AxiosResponse<Purpose>, AxiosError, PurposeRequestBody>) => {
  const axiosWithInterceptors = useAxiosWithLogOut();
  const { organizationId } = useActiveOrganization();
  const queryClient = useQueryClient();

  return useMutation(
    async (purpose: PurposeRequestBody) => {
      const requestBody = {
        ...purpose,
        organization_id: organizationId,
      };
      return axiosWithInterceptors.post(`${CONFIG.environmentConfig.apiBaseUrl}metadata/purposes?$translations=true`, requestBody);
    },
    {
      ...options,
      onSuccess: (newPurpose: AxiosResponse<Purpose>, variables, context) => {
        const purposesGet: PaginatedResponse<Purpose> = queryClient.getQueryData(['purposes', organizationId]);
        /* istanbul ignore if */
        if (purposesGet) {
          queryClient.setQueryData(['purposes', organizationId], {
            ...purposesGet,
            data: purposesGet.data.concat(newPurpose.data),
            total: purposesGet.total + 1,
          });
        }

        const allPurposesGet: PaginatedResponse<Purpose> = queryClient.getQueryData(['purposes']);
        /* istanbul ignore if */
        if (allPurposesGet) {
          queryClient.setQueryData(['purposes'], {
            ...allPurposesGet,
            data: allPurposesGet.data.concat(newPurpose.data),
            total: allPurposesGet.total + 1,
          });
        }

        if (options?.onSuccess) {
          options.onSuccess(newPurpose, variables, context);
        }
      },
    },
  );
};
