import { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import { useAxiosWithLogOut } from '@hooks';
import { PaginatedResponse, Country } from '@types';

/**
 * Fetches the full list of supported countries
 * */
export const useCountries = () => {
  const axiosWithInterceptors = useAxiosWithLogOut();

  const fetchPurposes = async () => {
    try {
      const response = await axiosWithInterceptors.get<PaginatedResponse<Country>>(`${CONFIG.environmentConfig.apiBaseUrl}metadata/countries?$limit=5000`);

      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  return useQuery<PaginatedResponse<Country>, AxiosError>(['countries'], fetchPurposes);
};
