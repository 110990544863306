import React from 'react';
import { UnmatchedVendorDomains } from '@components';

interface MatchVendorDomainsProps {
  selectedVendorHosts?: any[];
  setSelectedVendorHosts?: Function;
  matchingVendorHost?: string;
}
/**
 * MatchVendorDomains Page
 */
export const MatchVendorDomains = ({ selectedVendorHosts, matchingVendorHost, setSelectedVendorHosts }: MatchVendorDomainsProps): JSX.Element => {
  return (
    <main className="w-full">
      <UnmatchedVendorDomains selectedVendorHosts={selectedVendorHosts} matchingVendorHost={matchingVendorHost} setSelectedVendorHosts={setSelectedVendorHosts} />
    </main>
  );
};
