import { useActiveOrganization } from '@didomi/utility-react';
import { AxiosError, AxiosResponse } from 'axios';
import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import { useAxiosWithLogOut } from '@hooks';
import { PaginatedResponse, Vendor } from '@types';

/**
 * Deletes a vendor, makes sure to update the list of vendors to remove the vendor from there as well
 * */
export const useDeleteVendor = (options?: UseMutationOptions<AxiosResponse<Vendor>, AxiosError, string>) => {
  const axiosWithInterceptors = useAxiosWithLogOut();
  const { organizationId } = useActiveOrganization();
  const queryClient = useQueryClient();

  return useMutation(
    async (partnerId: string) => {
      return axiosWithInterceptors.delete(`${CONFIG.environmentConfig.apiBaseUrl}metadata/partners/${partnerId}?$translations=true`);
    },
    {
      ...options,
      onSuccess: (newVendor: AxiosResponse<Vendor>, variables, context) => {
        const vendorsGet: PaginatedResponse<Vendor> = queryClient.getQueryData(['vendors', organizationId]);
        /* istanbul ignore if */
        if (vendorsGet) {
          queryClient.setQueryData(['vendors', organizationId], {
            ...vendorsGet,
            data: vendorsGet.data.filter(p => p.id !== newVendor.data.id),
            total: vendorsGet.total - 1,
          });
        }

        if (options?.onSuccess) {
          options.onSuccess(newVendor, variables, context);
        }
      },
    },
  );
};
