import React from 'react';
import { ErrorBoundary } from '@didomi/helpers-react';
import { connect } from '@didomi/ui-foundation';
import * as utility from '@didomi/utility';
import { UtilityProvider } from '@didomi/utility-react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { App } from '@pages';
import presetDataManager from './dataManagerPreset';
// import { makeServer } from '@mocks';

// Setup Twind for data-manager only
connect('.data-manager-root', { presets: [presetDataManager()] });

// Uncomment this line to use Mirage to mock the server requests
// makeServer();

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 1,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      staleTime: Infinity,
    },
  },
});

const Root = () => {
  return (
    <UtilityProvider utility={utility}>
      <ErrorBoundary sentryConfig={{ sentryDsn: CONFIG.sentryDsn, environment: CONFIG.environment, release: CONFIG.release }}>
        <QueryClientProvider client={queryClient}>
          <ReactQueryDevtools />
          <App />
        </QueryClientProvider>
      </ErrorBoundary>
    </UtilityProvider>
  );
};

export { Root };
