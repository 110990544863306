import React from 'react';
import { DidomiButton, DidomiIllustration, DidomiModal, DidomiModalHeader, DidomiModalActions } from '@didomi/ui-atoms-react';
import { tx } from '@twind/core';

type RemoveItemConfirmationModalProps = {
  itemName: string;
  isOpen?: boolean;
  onCancel?: () => void;
  onProceed?: () => void;
};

/**
 * Remove item modal confirmation
 */
export const RemoveItemConfirmationModal = ({ itemName, isOpen, onCancel, onProceed }: RemoveItemConfirmationModalProps): JSX.Element => {
  return (
    <DidomiModal isOpen={isOpen} permanent="true" data-cy="remove-item-modal">
      <DidomiIllustration name="delete-pref-tree" className={tx('mb-10')}></DidomiIllustration>
      <DidomiModalHeader
        modalSubTitle="Please pay attention"
        modalTitle={`You're about to delete ${itemName}`}
        modalDescription="Are you sure you want to delete this item? "
      ></DidomiModalHeader>

      <DidomiModalActions className={tx('!pt-5')}>
        <DidomiButton variant="secondary" onClick={onCancel}>
          No, cancel
        </DidomiButton>
        <DidomiButton data-tracking="pmp-delete-preference-from-pl-in-tree" iconRight="delete" onClick={onProceed}>
          Yes, delete
        </DidomiButton>
      </DidomiModalActions>
    </DidomiModal>
  );
};
