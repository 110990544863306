import { NavigateOptions, To, useNavigate, useSearchParams } from 'react-router-dom';
import { useSPARouter } from '@didomi/utility-react';

/*
 * Hook checks `fromPath` query params and use it for navigation
 * If `fromPath` is not provided in the URL - default navigation is used
 * `fromPath` should be absolute path that would be used by useSPARouter.navigateToAbsolute
 */
export const useBackNavigation = () => {
  const navigate = useNavigate();
  let [searchParams] = useSearchParams();
  const { navigateToAbsolute } = useSPARouter();

  return (to: To, options?: NavigateOptions) => {
    const fromPath = searchParams.get('fromPath');

    if (fromPath) {
      navigateToAbsolute(fromPath);
    } else {
      options ? navigate(to, options) : navigate(to);
    }
  };
};
